import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useFragment } from '@apollo/client';
import { inject, observer } from 'mobx-react';
import { mergeQueryString, updateSearch } from 'bernie-core';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { SEARCH_ID } from 'src/common/constants/analytics';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { PropertyOffers } from '@shared-ui/lodging-property-offers';
import { RefTarget } from '@shared-ui/ref-context';
import { PropertyActivitiesCrossSellContextProvider } from '@shared-ui/retail-lodging-property-activities-cross-sell';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { InfositeQueryAdapter } from 'stores/adapters/infosite-query-adapter';
import { SearchQueryAdapter } from 'stores/adapters/search-query-adapter';
import { SearchCriteriaModel, SearchCriteriaUtils, singleUseQueryParams } from 'stores/models';
import { FocusTarget } from 'components/common/focus/focus-target';
import { SharedUiBookByPhoneWrapper } from 'components/infosite/shared-ui/details/book-by-phone-wrapper';
import { SharedUiTravelersLinkWrapper } from 'components/infosite/shared-ui/form/field-wrappers';
import { SharedUiOffersWrapper } from 'components/infosite/shared-ui/offers/offers-wrapper';
import { PropertyActivitiesCrossSell } from 'components/infosite/template-components/property-activities-cross-sell-wrapper';
import { StickyBookBar } from 'components/infosite/template-components/sticky-book-bar';
import { multiItemCompleteSearchCriteria } from 'components/multi-item/utils';
import { rewriteHistory } from 'utils/route-store-utils';
import { ReferrerContextProvider } from '../shared-ui/form/infosite-referrer-context';
import { DatelessPriceSummaryWrapper } from '../shared-ui/offers/dateless-price-summary-wrapper';
import { SharedUiLodgingOneKeyBurnSwitchWrapper } from '../shared-ui/offers/lodging-one-key-burn-switch-wrapper';
import { SharedUiLoyaltyDiscountToggleWrapper } from '../shared-ui/offers/loyalty-discount-toggle-wrapper';
import { SharedUiPriceInsightWrapper } from '../shared-ui/offers/price-insight-wrapper';
import { SearchWizardComposer } from './search-wizard-composer';
export const CACHED_PROPERTY_OFFERS_A11Y_LABEL_FRAGMENT = gql`
  fragment LoadingA11yLabel on OfferDetails {
    loading {
      accessibilityLabel
    }
  }
`;
export const RoomsAndRates = inject('currentHotel', 'context', 'multiItem', 'searchResults', 'experiment', 'analyticsSearch')(observer(({
  currentHotel,
  context,
  multiItem,
  searchResults,
  analyticsSearch
}) => {
  var _a, _b;
  const l10n = useLocalization();
  const history = useHistory();
  const location = useLocation();
  const {
    data: propertyOffers
  } = useFragment({
    fragment: CACHED_PROPERTY_OFFERS_A11Y_LABEL_FRAGMENT,
    from: {
      __typename: 'OfferDetails',
      id: currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId
    }
  });
  const accessibilityLabel = (_b = (_a = propertyOffers.loading) === null || _a === void 0 ? void 0 : _a.accessibilityLabel) !== null && _b !== void 0 ? _b : '';
  const onUpdateAvailabilityTravelers = fields => {
    currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData(fields);
    onUpdateResultsAvailabilityWizard(undefined, currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.offerSearchData, true);
  };
  const onUpdateResultsAvailabilityWizard = (event, offerSearchData, goBack = true) => {
    handleUpdateResults({
      accessibilityLabel,
      context,
      searchResults,
      l10n,
      history,
      location,
      event,
      criteria: Object.assign(Object.assign({}, currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria.toJSON(multiItemCompleteSearchCriteria)), SearchQueryAdapter.parseIn(offerSearchData)),
      goBack,
      analyticsSearch,
      currentHotel
    });
  };
  const onUpdateResults = (event, searchCriteria, goBack = true) => {
    handleUpdateResults({
      accessibilityLabel,
      context,
      searchResults,
      l10n,
      history,
      location,
      event,
      criteria: searchCriteria.toJSON(multiItemCompleteSearchCriteria),
      goBack,
      queryCombiner: (prevQuery, newQuery) => newQuery,
      analyticsSearch,
      currentHotel
    });
  };
  React.useEffect(() => {
    const {
      startDate,
      endDate,
      adults,
      children
    } = SearchQueryAdapter.parseOut(currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria);
    // 0 - children from search is undefined
    const childrenFormatted = typeof children === 'undefined' ? '' : children;
    currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData({
      startDate,
      endDate,
      adults,
      children: childrenFormatted
    });
  }, [currentHotel]);
  const isMultiItem = multiItem && multiItem.isPackageShoppingPath();
  const searchWizardComposerProps = {
    currentHotel,
    isMultiItem,
    onUpdateResults,
    onUpdateResultsAvailabilityWizard
  };
  const {
    exposure: {
      bucket: etsPointsToggleExperimentBucket
    }
  } = useExperiment('ETS_POINTS_TOGGLE');
  const roomsAndRatesComponent = React.createElement(ReferrerContextProvider, null, React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'three'
    }
  }, React.createElement(SharedUiBookByPhoneWrapper, null)), React.createElement(PropertyActivitiesCrossSellContextProvider, null, React.createElement(SharedUiOffersWrapper, null, React.createElement(PropertyOffers.Form, null, React.createElement("div", null, !isMultiItem && React.createElement(React.Fragment, null, React.createElement(Experiment, {
    name: "MultiUnitVR_Master_MVP",
    logExposure: false
  }, React.createElement(ExperimentControl, null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(SharedUiTravelersLinkWrapper, {
    handleChange: onUpdateAvailabilityTravelers
  })), React.createElement(ViewLarge, null))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(DatelessPriceSummaryWrapper, null)))), React.createElement(SearchWizardComposer, Object.assign({}, searchWizardComposerProps)), React.createElement(SharedUiPriceInsightWrapper, null), React.createElement(SharedUiLoyaltyDiscountToggleWrapper, null), React.createElement(SharedUiLodgingOneKeyBurnSwitchWrapper, {
    showBorder: false
  })))), React.createElement(PropertyActivitiesCrossSell, {
    skipSsr: true
  })), React.createElement(Viewport, null, React.createElement(ViewSmall, null, etsPointsToggleExperimentBucket === 0 || isMultiItem ? React.createElement("div", {
    "data-stid": "sticky-book-bar"
  }, React.createElement(StickyBookBar, null)) : React.createElement(React.Fragment, null)), React.createElement(ViewLarge, null, React.createElement(React.Fragment, null))));
  return React.createElement(ErrorBoundary, {
    label: "hotel.information.rooms.roomsAndRates"
  }, React.createElement(FocusTarget, {
    targetKey: "searchForm"
  }, React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement("div", {
    "data-stid": "rooms-rates"
  }, React.createElement(RefTarget, {
    name: "Offers",
    tabIndex: -1
  }, roomsAndRatesComponent)))));
}));
export const handleUpdateResults = ({
  accessibilityLabel,
  context,
  searchResults,
  l10n,
  history,
  location,
  event,
  criteria,
  goBack = true,
  queryCombiner,
  analyticsSearch,
  currentHotel
}) => {
  if (event) {
    event.preventDefault();
  }
  const keysToKeepFromOriginalQuery = key => !key.startsWith('pwaDialog') && !key.startsWith('rm') && !singleUseQueryParams.includes(key);
  analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.newSearchId();
  searchResults === null || searchResults === void 0 ? void 0 : searchResults.clearDefaultRequestFields();
  if (currentHotel) {
    currentHotel.searchCriteria = new SearchCriteriaModel(SearchCriteriaUtils.ensureCorrectTypes(criteria), l10n);
  }
  const params = Object.assign(Object.assign({}, criteria), {
    localDateFormat: context === null || context === void 0 ? void 0 : context.localDateFormat,
    [SEARCH_ID]: analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.searchId
  });
  const queryString = mergeQueryString(location.search, InfositeQueryAdapter.parseOut(params, l10n), keysToKeepFromOriginalQuery);
  if (goBack) {
    rewriteHistory(history, Object.assign(Object.assign({}, location), {
      search: queryString
    }));
  } else {
    liveAnnounce(accessibilityLabel, Level.POLITE);
    setTimeout(() => updateSearch({
      history,
      location,
      newParams: queryString,
      replace: true,
      queryCombiner
    }), 50);
  }
};