import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ClientLogger } from 'bernie-client';
import { updateSearch } from 'bernie-core';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { EGDSPageLoader } from '@egds/react-core/loader';
import { parse } from 'query-string';
import { SEARCH_ID } from 'src/common/constants/analytics';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { PropertyOffers } from '@shared-ui/lodging-property-offers';
import { INCOMPATIBLE_RATEPLAN } from 'stores/multi-item-store-util';
import { adaptToCartPropertyPrimaryKeyInput, adaptToCartPropertySearchContextInput } from 'components/multi-item/cart-adapter';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const reserveFormLogger = ClientLogger.getLoggerWithIdentifier('pwa.component.reserveForm');
export const LinkWrapper = ({
  href,
  children
}) => {
  const location = useLocation();
  // Temporary until the correct url is returned from the bff.
  // The href value for the error message is only the queryparam, not the
  // whole path.
  // When fixed, no need for the pathname and search anymore
  // TODO: add bff PR
  if (href === null || href === void 0 ? void 0 : href.includes('?')) {
    return React.createElement(Link, {
      to: href
    }, children);
  }
  return React.createElement(Link, {
    to: {
      pathname: location.pathname,
      search: href
    }
  }, children);
};
const paramsBuilder = (pillType, pillName, selected, currentParams) => {
  /**
   * Possible values for currentParams (eg)
   * paymentType = 'FREE_CANCELLATION,PAY_LATER'
   * amenities = ['PARKING','OCEAN_VIEW'];
   */
  const paramValue = currentParams[pillType];
  let preFilteredType = typeof paramValue === 'string' && paramValue !== '' && paramValue.split(',') || paramValue || [];
  if (selected && !preFilteredType.includes(pillName)) {
    preFilteredType.push(pillName);
  } else if (!selected && preFilteredType.includes(pillName)) {
    preFilteredType = preFilteredType.filter(item => item !== pillName);
  }
  return preFilteredType;
};
export const updateSearchAndFilters = (pill, history, location, analyticsSearch) => {
  var _a, _b;
  let pillType = '';
  const currentParams = parse(location.search);
  if (pill instanceof Array) {
    pill.forEach(option => {
      const {
        id: type,
        value: name,
        selected
      } = option;
      pillType = type;
      currentParams[pillType] = paramsBuilder(pillType, name, selected, currentParams);
    });
  } else {
    const {
      type,
      name,
      selected
    } = pill;
    pillType = type;
    currentParams[pillType] = paramsBuilder(pillType, name, selected, currentParams);
  }
  const newParams = {
    [pillType]: (_b = (_a = currentParams[pillType]) === null || _a === void 0 ? void 0 : _a.filter(Boolean).join()) !== null && _b !== void 0 ? _b : ''
  };
  analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.newSearchId();
  if (analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.searchId) {
    newParams[SEARCH_ID] = analyticsSearch.searchId;
  }
  updateSearch({
    history,
    location,
    newParams
  });
};
/**
 * Represents two different shared-ui versions of room and rates depending on the offers data given.
 * e.g. if verticalListings, then renders TnL1A version of property-offers
 * otherwise renders control version
 */
const PropertyOffersWrapper = ({
  children,
  inputs,
  analytics,
  context,
  currentHotel,
  multiItem,
  analyticsSearch
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = parse(location.search);
  const handleMultiItemFailure = formRef => reason => {
    var _a;
    if ((multiItem === null || multiItem === void 0 ? void 0 : multiItem.itemCount) === 0) {
      if (typeof ((_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.submit) === 'function') {
        formRef.current.submit();
      }
      reserveFormLogger.logEvent(new SystemEvent(SystemEventLevel.WARN, 'FALLBACK_TO_STANDALONE_BOOKING'));
    }
    // this is treating rare usecase, where user tries to reserve a rateplan that they shouldn't have seen in first place
    else if (reason === INCOMPATIBLE_RATEPLAN) {
      reserveFormLogger.logEvent(new SystemEvent(SystemEventLevel.WARN, 'INCOMPATIBLE_RATEPLAN'));
    }
  };
  const handleHotelPlusHotelCallBack = (propertyNaturalKeys, businessModel, noCreditCard, multiItemPriceToken, formRef) => {
    var _a;
    if (context && currentHotel) {
      (_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.addPropertyToCart(context, adaptToCartPropertyPrimaryKeyInput(propertyNaturalKeys), adaptToCartPropertySearchContextInput(currentHotel.searchCriteria, queryParams.regionId), businessModel, analytics, noCreditCard)) === null || _a === void 0 ? void 0 : _a.catch(handleMultiItemFailure(formRef));
    }
  };
  const handleSelectProductsFailure = reason => {
    if (!(multiItem === null || multiItem === void 0 ? void 0 : multiItem.errorMessage)) {
      multiItem === null || multiItem === void 0 ? void 0 : multiItem.setGenericError();
    }
    reserveFormLogger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'FAILED_TO_ADD_HOTEL_TO_MULTIITEM_SESSION'), reason);
  };
  const handleMultiItemCallBack = (propertyNaturalKeys, businessModel, noCreditCard, multiItemPriceToken, formRef, dealMarker) => {
    var _a;
    if (context && ((_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) === null || _a === void 0 ? void 0 : _a.id)) {
      multiItem === null || multiItem === void 0 ? void 0 : multiItem.selectProducts(context, multiItem.multiItemContext.id, multiItemPriceToken, adaptToCartPropertyPrimaryKeyInput(propertyNaturalKeys), [], [], dealMarker).then(redirectUrl => {
        window.location.href = redirectUrl;
      }).catch(handleSelectProductsFailure);
    }
  };
  const isMultiItem = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  // TODO This part is temporary to enable shared-ui-property-offers on all pages. This part will be moved to shared-ui as well
  const getMultiItemCallBack = () => {
    if (multiItem === null || multiItem === void 0 ? void 0 : multiItem.showMultiPropExperience) {
      return handleHotelPlusHotelCallBack;
    } else if (isMultiItem) {
      return handleMultiItemCallBack;
    }
  };
  const handleClick = pill => {
    updateSearchAndFilters(pill, history, location, analyticsSearch);
  };
  const offersProps = {
    inputs: inputs,
    defaultErrorMessage: multiItem === null || multiItem === void 0 ? void 0 : multiItem.genericError,
    LinkWrapper: LinkWrapper,
    updateSearchAndFilters: handleClick
  };
  const valueProps = React.createElement(PropertyOffers, Object.assign({}, offersProps, {
    handleMultiItemCallback: getMultiItemCallBack(),
    skipSsr: true
  }), children);
  if (isMultiItem) {
    return React.createElement(Experiment, {
      name: "MIT_PWA_PDP_Select_Products_Mutation_Callback_SharedUI"
    }, React.createElement(ExperimentControl, null, valueProps, React.createElement(EGDSPageLoader, {
      visible: multiItem === null || multiItem === void 0 ? void 0 : multiItem.pageLoading
    })), React.createElement(ExperimentVariant, {
      bucket: 1
    }, React.createElement(PropertyOffers, Object.assign({}, offersProps, {
      skipSsr: true
    }), children)));
  }
  return React.createElement(React.Fragment, null, valueProps);
};
const ObservedPropertyOffersWrapper = inject('analytics', 'context', 'currentHotel', 'multiItem', 'analyticsSearch')(observer(props => React.createElement(PropertyOffersWrapper, Object.assign({}, props))));
ObservedPropertyOffersWrapper.displayName = 'SharedUiPropertyOffers';
export const SharedUiOffersWrapper = withSharedUiPropertyOffers(ObservedPropertyOffersWrapper);