import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyFaq } from '@shared-ui/lodging-property-details';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const PropertyFaqWrapper = ({
  inputs
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  const label = 'hotel.property.faq';
  return React.createElement(ErrorBoundary, {
    label: label
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(EGDSSpacing, {
    margin: {
      blockstart: 'six'
    }
  }, React.createElement("div", null, React.createElement(PropertyFaq, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })))));
};
export const SharedUiPropertyFaqWrapper = withSharedUiPropertyDetails(PropertyFaqWrapper);
PropertyFaqWrapper.displayName = 'SharedUiPropertyFaqWrapper';