import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { InquiryProminence } from 'src/common/__generated__/api';
import { SimpleLazyLoadWrapper } from 'src/common/components/common/lazy/simple-lazy-load-wrapper';
import { RefTarget } from '@shared-ui/ref-context';
import { SharedUiContactHostWrapper } from '../shared-ui/offers/contact-host-wrapper';
export const PropertyContactHostWrapper = () => React.createElement(EGDSSpacing, {
  margin: {
    block: 'six'
  }
}, React.createElement(RefTarget, {
  name: "ContactHost",
  tabIndex: 0
}, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(SharedUiContactHostWrapper, {
  prominence: InquiryProminence.MEDIUM
}))));