import * as React from 'react';
import { inject } from 'mobx-react';
import { DeviceType } from 'src/common/__generated__/api';
import { useBexApi } from '@shared-ui/bex-api-context';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { UnitsCarousel } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const UnitsCarouselWrapper = inject('multiItem')(({
  inputs,
  multiItem
}) => {
  var _a, _b, _c, _d, _e;
  const {
    context
  } = useBexApi();
  const isMultiItem = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  const numberOfRooms = ((_c = (_b = (_a = inputs === null || inputs === void 0 ? void 0 : inputs.searchCriteria) === null || _a === void 0 ? void 0 : _a.primary) === null || _b === void 0 ? void 0 : _b.rooms) === null || _c === void 0 ? void 0 : _c.length) || 1;
  const isMobileOrTablet = ((_d = context === null || context === void 0 ? void 0 : context.device) === null || _d === void 0 ? void 0 : _d.type) === DeviceType.MOBILE_PHONE || ((_e = context === null || context === void 0 ? void 0 : context.device) === null || _e === void 0 ? void 0 : _e.type) === DeviceType.MOBILE_TABLET;
  if (numberOfRooms > 1 || isMultiItem || !isMobileOrTablet) {
    return null;
  }
  return React.createElement(Experiment, {
    name: "Lodging_PDP_Rooms_Overview_MWeb",
    logExposure: false
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(UnitsCarousel, {
    inputs: inputs,
    skipSsr: true
  })));
});
UnitsCarouselWrapper.displayName = 'SharedUiUnitsCarouselWrapper';
export const SharedUiUnitsCarouselWrapper = withSharedUiPropertyOffers(UnitsCarouselWrapper);