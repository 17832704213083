import * as React from 'react';
import { boolean, object, optional, safeParse } from 'valibot';
import { SharedUiNavigationBarWrapper } from 'components/infosite/shared-ui/details/navigation-bar-wrapper';
import { NavigationRegion } from 'components/layout/pages/navigation-region';
export const PropertyNavigation = ({
  templateComponent
}) => {
  var _a;
  const configSchema = optional(object({
    showReserveBtn: optional(boolean()),
    showNightlyPrice: optional(boolean()),
    showReserveBtnOnLoad: optional(boolean())
  }));
  const result = safeParse(configSchema, templateComponent.config);
  if (!result.success) {
    return null;
  }
  const {
    showReserveBtn,
    showNightlyPrice,
    showReserveBtnOnLoad
  } = (_a = result.output) !== null && _a !== void 0 ? _a : {};
  return React.createElement(NavigationRegion, {
    className: "navigation-bar-region constrained"
  }, React.createElement(SharedUiNavigationBarWrapper, {
    showReserveBtn: showReserveBtn,
    showNightlyPrice: showNightlyPrice,
    showReserveBtnOnLoad: showReserveBtnOnLoad
  }));
};