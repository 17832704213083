import * as React from 'react';
import { PropertySpaceDetails } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const SpaceDetailsWrapper = ({
  inputs,
  className
}) => React.createElement(PropertySpaceDetails, {
  inputs: inputs,
  className: className,
  skipSsr: true
});
SpaceDetailsWrapper.displayName = 'SpaceDetailsWrapper';
export const SharedUiSpaceDetailsWrapper = withSharedUiPropertyOffers(SpaceDetailsWrapper);