import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject } from 'mobx-react';
import { QueryRoute, updateSearch } from 'bernie-core';
import { Layer } from 'bernie-view';
import { EGDSDatePickerTriggeredSelector, EGDSNewDatePicker } from '@egds/react-core/date-picker';
import { EGDSLink } from '@egds/react-core/link';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useExperiment } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { FormInputsProvider, useFetchRatesAction } from '@shared-ui/lodging-form';
import { SearchCriteriaModel } from 'stores/models';
import { useFormInputs } from 'components/infosite/shared-ui/form/use-form-inputs';
const isValidDate = date => Boolean(date && date.toDateString() !== 'Invalid Date');
const DateField = inject('analytics', 'currentHotel')(props => {
  const {
    buttonText,
    analytics,
    rfrr,
    triggeredSelector,
    currentHotel
  } = props;
  const {
    formatDateString,
    formatDate,
    formatText,
    getWeekData
  } = useLocalization();
  const history = useHistory();
  const location = useLocation();
  const {
    exposure: availCalHighAndLowWebExposure,
    logExperiment: logAvailCalHighAndLowWebExperiment
  } = useExperiment('AvailCal_High_and_Low_Web');
  const offerSearchData = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.offerSearchData;
  const startDate = offerSearchData === null || offerSearchData === void 0 ? void 0 : offerSearchData.startDate;
  const endDate = offerSearchData === null || offerSearchData === void 0 ? void 0 : offerSearchData.endDate;
  const localizedDateless = formatText('hotels.search.form.toDate.placeholder');
  const startDateFormatted = startDate ? formatDateString(startDate, {
    skeleton: 'MMMEd'
  }) : localizedDateless;
  const endDateFormatted = endDate ? formatDateString(endDate, {
    skeleton: 'MMMEd'
  }) : localizedDateless;
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const checkInDate = startDate ? new Date(startDate) : undefined;
  const checkOutDate = endDate ? new Date(endDate) : undefined;
  const [rates, disclaimer, fetchRates] = useFetchRatesAction(isValidDate(checkInDate) && isValidDate(checkOutDate));
  const open = () => {
    logAvailCalHighAndLowWebExperiment(availCalHighAndLowWebExposure);
    setShowDatePicker(true);
    if (checkInDate && isValidDate(checkInDate)) {
      fetchRates(checkInDate);
    }
    updateSearch({
      history,
      location,
      newParams: {
        pwaDialog: `datepicker-${triggeredSelector}`
      }
    });
    if (typeof (analytics === null || analytics === void 0 ? void 0 : analytics.trackEvent) === 'function') {
      if (rfrr) {
        analytics.trackEvent(rfrr, 'Open Date Picker', analytics.omnitureData);
      }
      if (rates && Object.keys(rates).length > 0) {
        analytics.trackEvent('HOT.HIS.Property_Calendar.Rates', 'Calendar Rates visible to user');
      }
    }
  };
  const save = (start, end) => {
    props.onChange && props.onChange({
      target: [{
        name: 'startDate',
        value: formatDate(start, {
          raw: 'yyyy-MM-dd'
        })
      }, {
        name: 'endDate',
        value: formatDate(end, {
          raw: 'yyyy-MM-dd'
        })
      }]
    });
  };
  const dismiss = () => {
    setShowDatePicker(false);
    history.goBack();
  };
  const datePickerAnalytics = {
    closeAnalytics: analytics === null || analytics === void 0 ? void 0 : analytics.setupTracker(':ChangeDatesClose', 'Close Date Picker', null),
    doneAnalytics: analytics === null || analytics === void 0 ? void 0 : analytics.setupTracker(':ChangeDatesDone', 'Apply Date Picker', null)
  };
  const routerLinkWrapper = children => React.createElement(React.Fragment, null, children);
  const firstDayOfWeek = parseInt(getWeekData().firstDay.toUpperCase(), 10); // No mount callback
  const dateFieldProps = {
    routerLinkWrapper,
    showDatePicker,
    useCustomTrigger: true
  };
  const datePickerProps = {
    analytics: datePickerAnalytics,
    dateFieldProps,
    datePickerContent: rates,
    footerText: disclaimer,
    onDismissCB: dismiss,
    onSubmitCB: save,
    buttonText,
    startDate: startDate !== null && startDate !== void 0 ? startDate : '',
    endDate,
    doubleMonths: true,
    daysBookableInAdvance: SearchCriteriaModel.NUM_OF_DAYS_BOOKABLE_IN_ADVANCE,
    firstDayOfWeek,
    midnightBookingOffset: SearchCriteriaModel.MIDNIGHT_BOOKING_OFFSET_IN_HOURS,
    triggeredSelector
  };
  return React.createElement(React.Fragment, null, React.createElement(EGDSLink, {
    align: "center"
  }, React.createElement("button", {
    onClick: open
  }, triggeredSelector === EGDSDatePickerTriggeredSelector.START ? startDateFormatted : endDateFormatted)), showDatePicker && React.createElement(QueryRoute, {
    query: {
      pwaDialog: `datepicker-${triggeredSelector}`
    }
  }, React.createElement(Layer, {
    id: `home-datepicker-${triggeredSelector}`,
    onDismiss: dismiss
  }, React.createElement(EGDSNewDatePicker, Object.assign({}, datePickerProps)))));
});
DateField.defaultProps = {
  startDate: '',
  endDate: ''
};
export const CustomTriggerDateFieldWrapper = inject('currentHotel')(props => {
  const {
    currentHotel
  } = props;
  const {
    context
  } = useBexApi();
  const hotelId = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId;
  const searchCriteria = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria;
  const inputs = hotelId && searchCriteria && useFormInputs(hotelId, searchCriteria);
  const variable = Object.assign(Object.assign({}, inputs || {}), {
    context
  });
  if (!hotelId || !searchCriteria) {
    return null;
  }
  return React.createElement(FormInputsProvider, {
    value: variable
  }, React.createElement(DateField, Object.assign({}, props)));
});
CustomTriggerDateFieldWrapper.displayName = 'CustomTriggerDateFieldWrapper';