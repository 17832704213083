import * as React from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { inject, observer } from 'mobx-react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { ViewMedium, ViewSmall, Viewport } from '@shared-ui/viewport-context';
import { SearchFormWrapping } from '../../../common/universal-search-form/search-form-wrapping';
import { isMetaChannel, isSEMChannelDefaultDatesSearch } from '../../../common/universal-search-form/universal-search-form-utils';
export const SearchFormTop = inject('currentHotel', 'multiItem')(observer(({
  currentHotel,
  multiItem,
  dateLocalFormat
}) => {
  var _a;
  if (multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) {
    return null;
  }
  const spacingProps = {
    margin: {
      small: {
        inline: 'three',
        blockstart: 'three',
        blockend: 'three'
      },
      medium: {
        inline: 'unset',
        blockstart: 'eight',
        blockend: 'six'
      }
    }
  };
  const location = useLocation();
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const searchQueryParams = new URLSearchParams(location.search);
  const queryParamStartDate = searchQueryParams.get('chkin') || '';
  const queryParamEndDate = searchQueryParams.get('chkout') || '';
  const queryParamLatLong = searchQueryParams.get('latLong') || '';
  const rooms = ((_a = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria) === null || _a === void 0 ? void 0 : _a.rooms) || [];
  const isMetaOrSEMChannel = Boolean(isMetaChannel(searchQueryParams) || isSEMChannelDefaultDatesSearch(searchQueryParams));
  const searchFormProps = {
    dateLocalFormat: dateLocalFormat !== null && dateLocalFormat !== void 0 ? dateLocalFormat : 'YYYY-MM-DD',
    queryParamEndDate: queryParamEndDate,
    queryParamStartDate: queryParamStartDate,
    handleSubmit: () => null,
    showDatePicker: datePickerOpen,
    toggleDatePicker: setDatePickerOpen,
    autoOpenPlayback: false,
    includePlayback: true,
    dateFieldInitialFocus: false,
    queryParamLatLong: queryParamLatLong,
    alternateDestination: false,
    redirectToSearchResultOnSubmit: true,
    autoSubmitOnFieldChange: false,
    queryRooms: rooms
  };
  return React.createElement(React.Fragment, null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, isMetaOrSEMChannel ? React.createElement(Experiment, {
    name: "Metasearch_search_form_on_dated_PDP"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSSpacing, Object.assign({}, spacingProps), React.createElement("div", null, React.createElement(SearchFormWrapping, Object.assign({}, searchFormProps)))))) : React.createElement(React.Fragment, null)), React.createElement(ViewMedium, null, React.createElement(Experiment, {
    name: "Metasearch_search_form_on_dated_PDP"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSSpacing, Object.assign({}, spacingProps), React.createElement("div", null, React.createElement(SearchFormWrapping, Object.assign({}, searchFormProps)))))))));
}));
SearchFormTop.displayName = 'SearchFormTop';