import * as React from 'react';
import { ExperienceTemplateRenderer } from 'experience-template-renderer-react';
import { object, optional, safeParse, string } from 'valibot';
import { IntersectionTracker } from '@shared-ui/clickstream-analytics-context';
export const IntersectionTrackerWrapper = ({
  templateComponent
}) => {
  var _a;
  const {
    children,
    config
  } = templateComponent;
  const configSchema = optional(object({
    rfrrId: string(),
    linkName: string()
  }));
  const result = safeParse(configSchema, config);
  if (!result.success) {
    return null;
  }
  const {
    rfrrId = '',
    linkName = ''
  } = (_a = result.output) !== null && _a !== void 0 ? _a : {};
  return React.createElement(IntersectionTracker, {
    trackOnce: true,
    referrerId: rfrrId,
    linkName: linkName
  }, children && React.createElement(ExperienceTemplateRenderer, {
    templateComponents: children
  }));
};