export const rewriteHistory = (history, location, distance = -1) => {
  const listener = () => {
    // Some browsers (eg. Chrome) reset the document title when history.replace() is called.
    // So we capture the correct title here, and set it again after we call history.replace();
    const title = document.title;
    history.replace(location);
    document.title = '';
    document.title = title;
    window.removeEventListener('popstate', listener);
  };
  window.addEventListener('popstate', listener);
  history.go(distance);
};