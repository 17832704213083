import * as React from 'react';
export var CarouselType;
(function (CarouselType) {
  CarouselType["PROPERTY_RECOMMENDATIONS"] = "property-recommendations";
  CarouselType["SPONSORED_PROPERTIES"] = "sponsored-properties";
})(CarouselType || (CarouselType = {}));
const CarouselContext = React.createContext({
  sponsoredCarouselRendered: false,
  recommendationsCarouselRendered: false,
  handleSponsoredCarouselRendered: () => {},
  handleRecommendationsCarouselRendered: () => {}
});
export const CarouselProvider = ({
  children
}) => {
  const [sponsoredCarouselRendered, setSponsoredCarouselRendered] = React.useState(false);
  const [recommendationsCarouselRendered, setRecommendationsCarouselRendered] = React.useState(false);
  const handleSponsoredCarouselRendered = () => {
    setSponsoredCarouselRendered(true);
  };
  const handleRecommendationsCarouselRendered = () => {
    setRecommendationsCarouselRendered(true);
  };
  return React.createElement(CarouselContext.Provider, {
    value: {
      sponsoredCarouselRendered,
      handleSponsoredCarouselRendered,
      recommendationsCarouselRendered,
      handleRecommendationsCarouselRendered
    }
  }, children);
};
export default CarouselContext;