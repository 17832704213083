import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject } from 'mobx-react';
import { updateSearch } from 'bernie-core';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { useLocalization } from '@shared-ui/localization-context';
import { LoyaltyDiscountToggle } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
export const getHandleClick = (searchCriteria, history, location, l10n) => () => {
  updateSearch({
    history,
    location,
    newParams: {
      useRewards: String((searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.useRewards) !== 'true')
    },
    replace: true
  });
  if ((searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.useRewards) === 'true') {
    setTimeout(() => liveAnnounce(l10n === null || l10n === void 0 ? void 0 : l10n.formatText('common.pointsRemoved'), Level.POLITE), 500);
  } else {
    setTimeout(() => liveAnnounce(l10n === null || l10n === void 0 ? void 0 : l10n.formatText('common.pointsApplied'), Level.POLITE), 500);
  }
};
const LoyaltyDiscountToggleWrapper = inject('currentHotel')(({
  inputs,
  currentHotel
}) => {
  const history = useHistory();
  const location = useLocation();
  const l10n = useLocalization();
  return React.createElement(LoyaltyDiscountToggle, {
    inputs: inputs,
    onChange: getHandleClick(currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria, history, location, l10n),
    checked: (currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria.useRewards) === 'true',
    skipSsr: true
  });
});
LoyaltyDiscountToggleWrapper.displayName = 'LoyaltyDiscountToggleWrapper';
export const SharedUiLoyaltyDiscountToggleWrapper = withSharedUiPropertyOffers(LoyaltyDiscountToggleWrapper);