var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import * as React from 'react';
import { EGDSDatePickerTriggeredSelector } from '@egds/react-core/date-picker';
import { EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSText } from '@egds/react-core/text';
import { StepperButton } from 'src/common/components/infosite/rooms-and-rates/availability-wizard/internals/stepper-button';
import { LocalizedText } from '@shared-ui/localization-context';
import { CustomTriggerDateFieldWrapper } from 'components/infosite/shared-ui/form/date-field-custom-trigger';
import { DateType } from 'constants/dates';
export class DateStepper extends React.Component {
  render() {
    return React.createElement(EGDSSpacing, {
      margin: {
        block: 'three'
      }
    }, React.createElement("div", {
      className: "pwa-theme--grey-700"
    }, React.createElement(DateStepperWrapper, Object.assign({}, this.props))));
  }
}
const DateStepperWrapper = props => {
  const {
    dateType
  } = props;
  let dateStepperLabelKey;
  let stepperButtonLinkName;
  let stepperButtonRfrr;
  let stepperButtonType;
  if (dateType === DateType.CHECK_IN) {
    dateStepperLabelKey = 'hotels.search.form.fromDateHyphen.label';
    stepperButtonLinkName = 'Check-in Date';
    stepperButtonRfrr = ':ChangeDatesCheckIn';
    stepperButtonType = 'CHECK_IN';
  } else if (dateType === DateType.CHECK_OUT) {
    dateStepperLabelKey = 'hotels.search.form.toDateHyphen.label';
    stepperButtonLinkName = 'Check-out Date';
    stepperButtonRfrr = ':ChangeDatesCheckOut';
    stepperButtonType = 'CHECK_OUT';
  }
  const stepperProps = Object.assign({
    stepperButtonLinkName,
    stepperButtonRfrr,
    stepperButtonType
  }, props);
  return React.createElement(React.Fragment, null, React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSText, {
    size: 200
  }, React.createElement(LocalizedText, {
    message: dateStepperLabelKey || ''
  }))), React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'one'
    }
  }, React.createElement("div", null, React.createElement(EGDSLayoutGrid, {
    columns: 6
  }, React.createElement(EGDSLayoutGridItem, {
    colSpan: 1
  }, React.createElement(MinusOneDayStepper, Object.assign({}, stepperProps))), React.createElement(EGDSLayoutGridItem, {
    colSpan: 4
  }, React.createElement(EGDSText, {
    size: 400
  }, React.createElement("span", {
    "data-stid": "content-wizard-checkin"
  }, React.createElement(CustomTriggerHelper, Object.assign({}, props))))), React.createElement(EGDSLayoutGridItem, {
    colSpan: 1
  }, React.createElement(PlusOneDayStepper, Object.assign({}, stepperProps))))))));
};
const MinusOneDayStepper = props => {
  const {
    clickHandler,
    endDate,
    startDate,
    stepperButtonLinkName,
    stepperButtonRfrr,
    stepperButtonType
  } = props;
  return React.createElement(StepperButton, {
    clickHandler: clickHandler,
    endDate: endDate,
    linkName: `${stepperButtonLinkName} Minus One Day`,
    rfrr: `${stepperButtonRfrr}:MinusOneDay`,
    startDate: startDate,
    stepperType: `${stepperButtonType}_MINUS`
  });
};
const PlusOneDayStepper = props => {
  const {
    clickHandler,
    endDate,
    startDate,
    stepperButtonLinkName,
    stepperButtonRfrr,
    stepperButtonType
  } = props;
  return React.createElement(StepperButton, {
    clickHandler: clickHandler,
    endDate: endDate,
    linkName: `${stepperButtonLinkName} Plus One Day`,
    rfrr: `${stepperButtonRfrr}:PlusOneDay`,
    startDate: startDate,
    stepperType: `${stepperButtonType}_PLUS`
  });
};
export const CustomTriggerHelper = props => React.createElement(CustomTriggerDateFieldWrapper, Object.assign({}, getCommonDateFieldProps(props)));
export const getCommonDateFieldProps = props => {
  const {
      clickHandler,
      dateType
    } = props,
    dateFieldProps = __rest(props, ["clickHandler", "dateType"]);
  let triggeredSelector;
  let dateFieldRfrr;
  if (dateType === DateType.CHECK_IN) {
    triggeredSelector = EGDSDatePickerTriggeredSelector.START;
    dateFieldRfrr = ':ChangeDatesCheckIn';
  } else if (dateType === DateType.CHECK_OUT) {
    triggeredSelector = EGDSDatePickerTriggeredSelector.END;
    dateFieldRfrr = ':ChangeDatesCheckOut';
  }
  return Object.assign({
    triggeredSelector,
    onChange: clickHandler,
    rfrr: dateFieldRfrr
  }, dateFieldProps);
};