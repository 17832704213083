import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { VoiceOfTheCustomer } from '@shared-ui/customer-voice-of-the-customer';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyDetails } from '../details/shared-ui-property-details';
const VoiceOfTheCustomerWrapper = () => React.createElement(ErrorBoundary, {
  label: "voice-of-the-customer"
}, React.createElement(EGDSSpacing, {
  margin: {
    medium: {
      blockstart: 'six'
    }
  }
}, React.createElement("div", {
  "data-stid": "shared-ui-voice-of-the-customer"
}, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(VoiceOfTheCustomer, {
  inputs: {},
  skipSsr: true
})))));
export const SharedUiVoiceOfTheCustomerWrapper = withSharedUiPropertyDetails(VoiceOfTheCustomerWrapper);
SharedUiVoiceOfTheCustomerWrapper.displayName = 'SharedUiVoiceOfTheCustomerWrapper';