import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { SimpleLazyLoadWrapper } from 'src/common/components/common/lazy/simple-lazy-load-wrapper';
import { PropertyHostProfile } from '@shared-ui/lodging-property-details';
import { RefTarget } from '@shared-ui/ref-context';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const PropertyHostProfileWrapper = ({
  inputs,
  columns
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(RefTarget, {
    name: "Host",
    tabIndex: 0
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(PropertyHostProfile, {
    inputs: inputs,
    columns: columns,
    skipSsr: cSRPropertyDetailsQuery
  }))));
};
export const SharedUiPropertyHostProfileWrapper = withSharedUiPropertyDetails(PropertyHostProfileWrapper);
SharedUiPropertyHostProfileWrapper.displayName = 'SharedUiPropertyHostProfileWrapper';