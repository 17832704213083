/* eslint-disable max-lines */
import * as React from 'react';
import { inject } from 'mobx-react';
import { useFormAction } from '@shared-ui/lodging-form';
import { useRefContext } from '@shared-ui/ref-context';
import { PropertyAvailability } from '@shared-ui/retail-lodging-property-availability';
import { getDateRangeInput } from 'stores/adapters';
export const START_DATE_REF_NAME = 'startDate';
export const END_DATE_REF_NAME = 'endDate';
export const parseDate = date => ({
  day: date.getDate(),
  month: date.getMonth() + 1,
  year: date.getFullYear()
});
/* This date field wrapper is intended to be used for Single-Unit VRs. It uses the Shared-UI PropertyAvailability component
  which makes a GraphQL call for property availability and uses the EGDS Date Selector under the covers (as opposed to the EGDS Date Picker)
*/
export const SingleUnitDateFieldWrapper = inject('currentHotel')(({
  currentHotel
}) => {
  const hotelId = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId;
  const searchCriteria = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria;
  const dateRangeInput = searchCriteria && getDateRangeInput(searchCriteria);
  const {
    registerTarget
  } = useRefContext();
  // Register targets so that datepicker can be opened from different parts of the page
  const startDateRef = registerTarget(START_DATE_REF_NAME);
  const endDateRef = registerTarget(END_DATE_REF_NAME);
  const dispatch = useFormAction();
  const onSubmitCB = (startDate, endDate) => {
    dispatch({
      type: 'change',
      field: 'dates',
      value: {
        dates: {
          checkInDate: startDate ? parseDate(startDate) : null,
          checkOutDate: endDate ? parseDate(endDate) : null
        }
      }
    });
  };
  const dateRange = dateRangeInput ? {
    end: dateRangeInput.checkOutDate,
    start: dateRangeInput.checkInDate
  } : null;
  return React.createElement(PropertyAvailability, {
    startDateRef: startDateRef,
    endDateRef: endDateRef,
    inputs: {
      eid: String(hotelId),
      dateRange
    },
    onSubmit: onSubmitCB,
    skipSsr: true
  });
});