import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { AnsweringTravellerQuestionsContentSearch, IdentifierType } from '@shared-ui/retail-product-answering-traveller-questions';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
const AnsweringTravellerQuestionsWrapper = inject('currentHotel', 'context', 'analyticsSearch')(observer(({
  currentHotel,
  context,
  analyticsSearch
}) => {
  const {
    hotelId,
    searchCriteria
  } = currentHotel || {};
  const travellerQnAInputs = React.useMemo(() => ({
    productIdentifier: {
      id: (hotelId === null || hotelId === void 0 ? void 0 : hotelId.toString()) || '',
      type: IdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: context && adaptSearchCriteria(context, searchCriteria, undefined, undefined, analyticsSearch)
      }
    }
  }), [hotelId, searchCriteria, context]);
  if (!hotelId || !searchCriteria) {
    return null;
  }
  return React.createElement(ErrorBoundary, {
    label: "hotel.information.answering-traveller-question"
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement("div", {
    "data-stid": "answering-traveller-questions"
  }, React.createElement(AnsweringTravellerQuestionsContentSearch, {
    inputs: travellerQnAInputs,
    skipSsr: true
  }))));
}));
export const SharedUiAnsweringTravellerQuestionsWrapper = AnsweringTravellerQuestionsWrapper;
AnsweringTravellerQuestionsWrapper.displayName = 'SharedUiAnsweringTravellerQuestionsWrapper';