import { useState } from 'react';
import * as React from 'react';
import { ClientLogger } from 'bernie-client';
import { getBexApiContext } from 'bernie-context';
import { merge } from 'bernie-core';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { getPerformanceMetrics } from 'bernie-plugin-analytics';
import { ErrorBoundary } from 'bernie-view';
import { EGDSCard } from '@egds/react-core/cards';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSText } from '@egds/react-core/text';
import { getDateRangeInput } from 'src/common/stores/adapters';
import { SearchCriteriaModel } from 'src/common/stores/models/search-criteria-model';
import { useStores } from 'src/common/stores/use-stores';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { useFormAction } from '@shared-ui/lodging-form';
import { PropertyRatesDateSelector } from '@shared-ui/lodging-property-rates-date-selector';
import { useRefContext } from '@shared-ui/ref-context';
import { CurrentHotelStore } from 'stores/current-hotel-store';
import { SearchValidation } from 'components/infosite/rooms-and-rates/availability-wizard/internals/search-validation';
import { DateStepper } from './internals/date-stepper';
import { DateType } from 'constants/dates';
const {
  withKeys
} = merge;
const logger = ClientLogger.getLoggerWithIdentifier('availabilityWizard.error');
export const START_DATE_REF_NAME = 'startDate';
export const END_DATE_REF_NAME = 'endDate';
export const parseDate = date => ({
  day: date.getDate(),
  month: date.getMonth() + 1,
  year: date.getFullYear()
});
export const AvailabilityWizard = props => {
  var _a, _b, _c;
  const {
    analytics,
    currentHotel,
    context
  } = useStores();
  const {
    offerSearchData,
    searchCriteria,
    hotelId
  } = currentHotel;
  const dateRangeInput = searchCriteria && getDateRangeInput(searchCriteria);
  const startDate = (_a = offerSearchData === null || offerSearchData === void 0 ? void 0 : offerSearchData.startDate) !== null && _a !== void 0 ? _a : '';
  const endDate = (_b = offerSearchData === null || offerSearchData === void 0 ? void 0 : offerSearchData.endDate) !== null && _b !== void 0 ? _b : '';
  const {
    history
  } = props;
  const {
    formatText,
    formatDate
  } = useLocalization();
  const dispatch = useFormAction();
  const {
    registerTarget
  } = useRefContext();
  const startDateRef = registerTarget(START_DATE_REF_NAME);
  const endDateRef = registerTarget(END_DATE_REF_NAME);
  const dateRange = dateRangeInput ? {
    end: dateRangeInput.checkOutDate,
    start: dateRangeInput.checkInDate
  } : null;
  const [errorState, setErrorState] = useState({
    inputErrors: {}
  });
  const searchValidator = new SearchValidation(formatText, context);
  const logTracking = (rfrr, linkName) => {
    if (analytics && typeof analytics.clickTracker === 'function') {
      analytics === null || analytics === void 0 ? void 0 : analytics.clickTracker(rfrr, linkName, null, null)();
    }
  };
  const hasInputErrors = validationItems => {
    const temp = validationItems && validationItems;
    for (const key in temp.inputErrors) {
      if (temp.inputErrors[key] !== undefined) {
        return true;
      }
    }
  };
  const handleSubmit = (isTriggeredByStepper = false) => {
    var _a;
    const submitErrorState = {
      inputErrors: {}
    };
    const offerSearchData = (_a = currentHotel.offerSearchData) !== null && _a !== void 0 ? _a : {};
    const startDateError = searchValidator.startDate(offerSearchData);
    const endDateError = searchValidator.endDate(offerSearchData);
    const childrenError = searchValidator.children(offerSearchData);
    if (startDateError || endDateError || childrenError) {
      submitErrorState.inputErrors = {
        startDate: startDateError,
        endDate: endDateError,
        children: childrenError
      };
    }
    if (hasInputErrors(submitErrorState)) {
      if (!isTriggeredByStepper && history) {
        history.goBack();
      }
      const errorMessages = [];
      const errors = submitErrorState.inputErrors;
      for (const i in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, i) && errors[i] !== undefined) {
          errorMessages.push(errors[i]);
        }
      }
      if (errorMessages.length > 0) {
        logger.logEvent(new SystemEvent(SystemEventLevel.WARN, 'SEARCH_FORM_HANDLE_SUBMIT'), JSON.stringify(errorMessages));
      }
    } else {
      getPerformanceMetrics().reset();
      const {
        onUpdateResults
      } = props;
      if (onUpdateResults && typeof onUpdateResults === 'function') {
        if (isTriggeredByStepper) {
          onUpdateResults(undefined, currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.offerSearchData, false);
          logTracking('ChangeDatesStepper:Submit', 'Submitsearch using date stepper buttons');
        } else {
          onUpdateResults(undefined, currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.offerSearchData, true);
          logTracking('FORM.SUBMIT', 'Submit search form');
        }
      }
    }
    setErrorState(submitErrorState);
  };
  const onSubmitDateSelector = (startDate, endDate) => {
    const targetDictionary = {};
    if (startDate) {
      targetDictionary.startDate = formatDate(startDate, {
        raw: 'yyyy-MM-dd'
      });
    }
    if (endDate) {
      targetDictionary.endDate = formatDate(endDate, {
        raw: 'yyyy-MM-dd'
      });
    }
    const offerSearchData = withKeys(CurrentHotelStore.offerSearchKeys).merge({}, targetDictionary);
    currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData(offerSearchData);
    dispatch({
      type: 'change',
      field: 'dates',
      value: {
        dates: {
          checkInDate: startDate ? parseDate(startDate) : null,
          checkOutDate: endDate ? parseDate(endDate) : null
        }
      }
    });
    const {
      onUpdateResults
    } = props;
    if (onUpdateResults && typeof onUpdateResults === 'function') {
      onUpdateResults(undefined, currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.offerSearchData, false);
    }
  };
  const handleChange = (event, isTriggeredByStepper) => {
    let target = event.target;
    if (!Array.isArray(target)) {
      target = [target];
    }
    const targetDictionary = {};
    target.forEach(searchChangeProp => {
      targetDictionary[searchChangeProp.name] = searchChangeProp.value;
    });
    const offerSearchData = withKeys(CurrentHotelStore.offerSearchKeys).merge({}, targetDictionary);
    currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData(offerSearchData);
    handleSubmit(isTriggeredByStepper);
  };
  const stepperProps = {
    clickHandler: handleChange,
    endDate,
    startDate,
    invalid: (_c = errorState.inputErrors) === null || _c === void 0 ? void 0 : _c.startDate,
    midnightBookingOffsetHours: SearchCriteriaModel.MIDNIGHT_BOOKING_OFFSET_IN_HOURS,
    numDaysBookableInAdvance: SearchCriteriaModel.NUM_OF_DAYS_BOOKABLE_IN_ADVANCE
  };
  if (!currentHotel) return null;
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(Experiment, {
    name: "Rate_Calendar_Adjusted_Highlighting_Web"
  }, React.createElement(ExperimentControl, null, React.createElement("div", null, React.createElement(ErrorBoundary, {
    label: "hotel.information.rooms.availabilityWizardStepper"
  }, React.createElement(EGDSCard, null, React.createElement(EGDSText, {
    align: "center"
  }, React.createElement(EGDSLayoutFlex, {
    direction: "row",
    justifyContent: "space-between"
  }, React.createElement(EGDSLayoutFlexItem, {
    minWidth: "half_width"
  }, React.createElement(EGDSLayoutFlex, {
    direction: "column",
    justifyContent: "space-between"
  }, React.createElement(DateStepper, Object.assign({
    buttonText: formatText('hotels.infosite.searchForm.availabilityButton.label')
  }, stepperProps, {
    dateType: DateType.CHECK_IN
  })))), React.createElement(EGDSLayoutFlexItem, {
    minWidth: "half_width"
  }, React.createElement(EGDSLayoutFlex, {
    direction: "column",
    justifyContent: "space-between"
  }, React.createElement(DateStepper, Object.assign({
    buttonText: formatText('hotels.infosite.searchForm.availabilityButton.label')
  }, stepperProps, {
    dateType: DateType.CHECK_OUT
  })))))))))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, dateRange && React.createElement(PropertyRatesDateSelector, {
    startDateRef: startDateRef,
    endDateRef: endDateRef,
    inputs: {
      eid: String(hotelId),
      dateRange,
      context: getBexApiContext(context)
    },
    onSubmit: onSubmitDateSelector
  }))));
};