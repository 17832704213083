/**
 * Returns  structured data for passing necessary option for the clickstream reset function
 */
export const structureClickstreamResetData = (pageName, pageNameDetailed) => ({
  data: {
    experience: {
      page_name: pageName,
      page_name_detailed: pageNameDetailed
    }
  }
});