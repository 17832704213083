import * as React from 'react';
import { PropertyLevelOffersMessage } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PropertyLevelOffersMessageWrapper = ({
  inputs
}) => React.createElement(PropertyLevelOffersMessage, {
  inputs: inputs,
  skipSsr: true
});
PropertyLevelOffersMessageWrapper.displayName = 'PropertyLevelOffersMessageWrapper';
export const SharedUiPropertyLevelOffersMessageWrapper = withSharedUiPropertyOffers(PropertyLevelOffersMessageWrapper);