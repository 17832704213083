import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ClientLogger } from 'bernie-client';
import { ErrorBoundary } from 'bernie-view';
import { EssClientLogger } from 'typeahead-client-logger';
import { useClickstreamAnalytics } from '@shared-ui/clickstream-analytics-context';
import { Map } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { InfositePageName } from 'constants/pages';
const getProductLine = uisPageName => uisPageName === InfositePageName.SA.valueOf() ? 'Lodging' : 'Package';
const PropertyMap = inject('page', 'map', 'analytics')(observer(({
  inputs,
  onClose,
  page,
  map,
  analytics,
  closeMapDialogData
}) => {
  var _a;
  const {
    reset
  } = useClickstreamAnalytics();
  const triggerRef = React.useRef(null);
  const pageName = (page === null || page === void 0 ? void 0 : page.pageName) || InfositePageName.SA;
  const productLine = getProductLine(pageName);
  reset({
    data: {
      experience: {
        // eslint-disable-next-line camelcase
        page_product_line: productLine
      }
    },
    config: {
      isEgClickstream: true
    }
  });
  const setSavedFeature = map ? customPlace => map.setCustomPlace(customPlace) : undefined;
  const savedFeature = (_a = map === null || map === void 0 ? void 0 : map.customPlaces) === null || _a === void 0 ? void 0 : _a[0];
  const essLogger = new EssClientLogger(analytics, ClientLogger, '');
  const handleClose = () => {
    var _a;
    onClose();
    if ((_a = closeMapDialogData === null || closeMapDialogData === void 0 ? void 0 : closeMapDialogData.triggerRef) === null || _a === void 0 ? void 0 : _a.current) {
      closeMapDialogData.triggerRef.current.focus();
    }
  };
  const mapProps = {
    inputs,
    onClose: handleClose,
    setSavedFeature,
    savedFeature,
    essLogger,
    triggerRef
  };
  return React.createElement(ErrorBoundary, {
    label: "shared-ui.details.map"
  }, React.createElement("div", {
    className: "shared-ui-map uitk-dialog-layer-responsive",
    "data-stid": "infosite-map"
  }, React.createElement(Map, Object.assign({}, mapProps))));
}));
export const SharedUiMap = withSharedUiPropertyDetails(PropertyMap);
SharedUiMap.displayName = 'SharedUiMap';