import * as React from 'react';
import { gql, useFragment } from '@apollo/client';
export const CACHED_PROPERTY_NAME_FRAGMENT = gql`
  fragment SummaryHeader on PropertyInfo {
    summary {
      name
    }
  }
`;
const setPageTitle = (page, title) => {
  page.title = title;
  document.title = title;
};
export const useInfositePageTitleCacheRead = (currentHotel, page) => {
  const {
    data: propertyInfo
  } = useFragment({
    fragment: CACHED_PROPERTY_NAME_FRAGMENT,
    from: {
      __typename: 'PropertyInfo',
      id: currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId
    }
  });
  React.useEffect(() => {
    var _a;
    if ((_a = propertyInfo.summary) === null || _a === void 0 ? void 0 : _a.name) {
      page && setPageTitle(page, propertyInfo.summary.name);
    }
  });
};