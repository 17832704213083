import * as React from 'react';
import { gql, useFragment } from '@apollo/client';
export const CACHED_PROPERTY_OFFERS_MULTI_ITEM_FRAGMENT = gql`
  fragment ShoppingContext on OfferDetails {
    shoppingContext {
      multiItem {
        id
      }
    }
  }
`;
export const useMultiItemCacheRead = (currentHotel, multiItem) => {
  const {
    data: propertyOffers
  } = useFragment({
    fragment: CACHED_PROPERTY_OFFERS_MULTI_ITEM_FRAGMENT,
    from: {
      __typename: 'OfferDetails',
      id: currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId
    }
  });
  React.useEffect(() => {
    var _a, _b;
    if ((_b = (_a = propertyOffers.shoppingContext) === null || _a === void 0 ? void 0 : _a.multiItem) === null || _b === void 0 ? void 0 : _b.id) {
      multiItem === null || multiItem === void 0 ? void 0 : multiItem.updateSessionId(propertyOffers.shoppingContext.multiItem.id);
    }
  });
};