/* eslint-disable max-lines */
import * as React from 'react';
import { inject } from 'mobx-react';
import { TravelerSelector } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from '../offers/shared-ui-property-offers';
const DIALOG_ID = 'traveler-selector-wrapper';
const TravelersSelectorWrapper = inject('currentHotel')(({
  inputs,
  currentHotel
}) => {
  const ref = React.useRef(null);
  if (!currentHotel) {
    return null;
  }
  const updateStoreWithTravelerUrlData = travelerSelectorPayload => {
    const {
      children,
      adults = 0,
      isPetsIncluded: petsIncluded
    } = travelerSelectorPayload;
    currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData({
      adults,
      children: children.join(','),
      petsIncluded
    });
  };
  return React.createElement(TravelerSelector, {
    inputs: inputs,
    dialogId: DIALOG_ID,
    updateStoreWithTravelerUrlData: updateStoreWithTravelerUrlData,
    triggerRef: ref,
    isNestDialog: false,
    skipSsr: true
  });
});
TravelersSelectorWrapper.displayName = 'TravelersSelectorWrapper';
export const SharedUiTravelersSelectorWrapper = withSharedUiPropertyOffers(TravelersSelectorWrapper);