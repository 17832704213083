import * as React from 'react';
import { PropertyLegalDisclaimer } from '@shared-ui/lodging-property-offers';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PropertyLegalDisclaimerWrapper = React.memo(({
  inputs
}) => React.createElement(SimpleLazyLoadWrapper, null, React.createElement("div", {
  "data-stid": "property-legal-disclaimer"
}, React.createElement(PropertyLegalDisclaimer, {
  inputs: inputs,
  skipSsr: true
}))));
PropertyLegalDisclaimerWrapper.displayName = 'PropertyLegalDisclaimerWrapper';
export const SharedUiPropertyLegalDisclaimerWrapper = withSharedUiPropertyOffers(PropertyLegalDisclaimerWrapper);