import { PropertyFilterAmenity } from '../__generated__/api';
import { adaptSearchCriteria } from '../stores/adapters/bex-api/common/search-criteria-adapter';
export const isPetsAmenityFilterPresent = (context, currentHotel) => {
  var _a;
  if (!currentHotel || !context) {
    return false;
  }
  const searchCriteria = adaptSearchCriteria(context, currentHotel.searchCriteria);
  const possibleSelectionValuesForPets = [PropertyFilterAmenity.PETS, 'pets_allowed'];
  return ((_a = searchCriteria.secondary) === null || _a === void 0 ? void 0 : _a.selections) && searchCriteria.secondary.selections.some(selection => possibleSelectionValuesForPets.includes(selection.value)) || false;
};