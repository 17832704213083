/* eslint-disable max-lines */
import * as React from 'react';
import { inject } from 'mobx-react';
import { useBexApi } from '@shared-ui/bex-api-context';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { FormInputsProvider, SubmitButton, TravelersField, TravelersFieldTriggerType } from '@shared-ui/lodging-form';
import { getDateRangeInput, getRooms } from 'stores/adapters';
import { SharedUiTravelersSelectorWrapper } from '../offers/shared-ui-traveler-selector-wrapper';
import { ActionProviderDatedMobile } from './action-provider';
export const SharedUiTravelersWrapper = inject('context', 'currentHotel')(({
  doneLabel,
  closeOnSubmit = true,
  trigger = TravelersFieldTriggerType.INPUT,
  isSingleUnit = false
}) => {
  const {
    formatText
  } = useLocalization();
  const travelersData = {
    __typename: 'LodgingFormTravelers',
    label: formatText('hotels.search.form.travelers.label'),
    travelersSummary: {
      __typename: 'TravelersSummary',
      roomsAndGuests: isSingleUnit ? 'hotels.roomPicker.roomSummary.singleUnit' : 'hotels.roomPicker.roomSummary'
    },
    roomPicker: {
      __typename: 'RoomPicker',
      toolBarIconLabel: formatText('hotels.roomPicker.cancelButton.a11yLabel'),
      toolBarTitle: formatText('hotels.roomPicker.title'),
      addRoomText: isSingleUnit ? '' : formatText('hotels.roomPicker.addRoomButton.label'),
      doneLabel: doneLabel || formatText('hotels.roomPicker.doneButton.label'),
      roomAddedLiveAnnounce: formatText('hotels.roomPicker.roomAdded.liveAnnounce', '{0}'),
      room: {
        __typename: 'RoomField',
        roomTitleAriaLabel: isSingleUnit ? '' : formatText('hotels.roomPicker.room.a11yLabelRoom', '{0}', '{1}'),
        roomTitle: isSingleUnit ? '' : formatText('hotels.roomPicker.room.title', '{0}'),
        adultsStepInput: {
          __typename: 'StepInput',
          title: formatText('hotels.roomPicker.adults.text'),
          increaseText: formatText('hotels.roomPicker.increaseAdults.text', '{0}'),
          decreaseText: formatText('hotels.roomPicker.decreaseAdults.text', '{0}'),
          caption: null
        },
        childrenStepInput: {
          __typename: 'StepInput',
          title: formatText('hotels.roomPicker.children.text'),
          increaseText: formatText('hotels.roomPicker.increaseChildren.text', '{0}'),
          decreaseText: formatText('hotels.roomPicker.decreaseChildren.text', '{0}'),
          caption: formatText('hotels.roomPicker.children.caption')
        },
        removeRoomButtonLabel: formatText('hotels.roomPicker.removeRoomButton.label'),
        removeRoomButtonAriaLabel: formatText('hotels.roomPicker.removeRoomButton.a11yLabel', '{0}', '{1}'),
        roomRemovedLiveAnnounce: formatText('hotels.roomPicker.roomRemoved.liveAnnounce', '{0}'),
        childrenAges: {
          __typename: 'ChildrenAges',
          selectChildAgeLabel: formatText('hotels.roomPicker.childAgeInput.label', '{0}'),
          youngestChildAgeText: formatText('hotels.roomPicker.youngestChildAge.text'),
          childAgeUpdatedLiveAnnounce: formatText('hotels.roomPicker.childUpdated.liveAnnounce', '{0}', '{1}')
        }
      },
      accessibility: ''
    }
  };
  return React.createElement(TravelersField, {
    fieldName: "travelers",
    data: travelersData,
    closeOnSubmit: closeOnSubmit,
    trigger: trigger
  });
});
export const SharedUiSubmitButtonWrapper = ({
  text
}) => {
  const submitButtonData = {
    __typename: 'LodgingFormSubmitButton',
    label: text
  };
  return React.createElement(SubmitButton, {
    fieldName: "submit",
    data: submitButtonData
  });
};
export const SharedUiTravelersLinkWrapper = inject('currentHotel')(({
  currentHotel,
  handleChange
}) => {
  const {
    formatText
  } = useLocalization();
  const hotelId = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId;
  const searchCriteria = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria;
  const {
    context
  } = useBexApi();
  const inputs = React.useMemo(() => ({
    propertyId: String(hotelId),
    dateRange: searchCriteria ? getDateRangeInput(searchCriteria) : null,
    roomInput: searchCriteria ? getRooms(searchCriteria) : []
  }), [hotelId, searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.startDate, searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.endDate, searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.rooms]);
  const variable = Object.assign(Object.assign({}, inputs || {}), {
    context
  });
  const doneLabel = formatText('hotels.infosite.searchForm.availabilityButton.label');
  return React.createElement(ActionProviderDatedMobile, {
    onChange: handleChange
  }, React.createElement(FormInputsProvider, {
    value: variable
  }, React.createElement(Experiment, {
    name: "Vrbex_traveler_selector"
  }, React.createElement(ExperimentControl, null, React.createElement(SharedUiTravelersWrapper, {
    doneLabel: doneLabel,
    trigger: TravelersFieldTriggerType.LINK,
    closeOnSubmit: false
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SharedUiTravelersSelectorWrapper, null)))));
});
SharedUiTravelersLinkWrapper.displayName = 'SharedUiTravelersLinkWrapper';