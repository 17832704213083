import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyStickyBookBar } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PropertyStickyBookBarWrapper = ({
  inputs
}) => React.createElement(EGDSSpacing, {
  margin: {
    blockend: 'two'
  }
}, React.createElement("div", {
  "data-stid": "dateless-price-summary"
}, React.createElement(PropertyStickyBookBar, {
  inputs: inputs,
  skipSsr: true,
  isSticky: false,
  align: 'stretch',
  hideCtaButton: true
})));
export const DatelessPriceSummaryWrapper = withSharedUiPropertyOffers(PropertyStickyBookBarWrapper);
DatelessPriceSummaryWrapper.displayName = 'DatelessPriceSummaryWrapper';