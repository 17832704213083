const breakpointsSizeMapping = {
  MOBILE_PHONE: 'sm',
  APP_PHONE: 'sm',
  MOBILE_TABLET: 'md',
  APP_TABLET: 'md',
  DESKTOP: 'lg',
  CHAT_APP: undefined,
  VOICE_APP: undefined
};
const PDPItemsVisible = {
  lg: 3,
  md: 2,
  sm: 1
};
export const getMinCarouselAds = device => {
  switch (breakpointsSizeMapping[device]) {
    case 'sm':
      {
        return PDPItemsVisible.sm + 1;
      }
    case 'md':
      {
        return PDPItemsVisible.md + 1;
      }
    default:
      return PDPItemsVisible.lg + 1;
  }
};
export const getMinCarouselAdsSelection = device => ({
  id: 'minCarouselAds',
  value: getMinCarouselAds(device).toString()
});