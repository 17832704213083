import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PropertyStickyPriceHeader } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
export const StickyPriceHeaderWrapper = inject('currentHotel')(observer(({
  inputs,
  hidePrice,
  hideCtaButton,
  wrapPriceSummary
}) =>
// Moved gating for this component to the consumer layer to prevent over-bucketing.
// Consider moving any hard restrictions back into this layer for launch. e.g. no H+H or PKG paths.
React.createElement(PropertyStickyPriceHeader, {
  inputs: inputs,
  focusTargetRef: "Offers",
  skipSsr: true,
  hidePrice: hidePrice,
  hideCtaButton: hideCtaButton,
  wrapPriceSummary: wrapPriceSummary
})));
StickyPriceHeaderWrapper.displayName = 'StickyPriceHeaderWrapper';
export const SharedUiStickyPriceHeaderWrapper = withSharedUiPropertyOffers(StickyPriceHeaderWrapper);