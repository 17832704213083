/* eslint-disable import/extensions */
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'bernie-view';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { SharedUiTravelersSelectorWrapper } from '../offers/shared-ui-traveler-selector-wrapper';
import { SharedUiTravelersWrapper } from './field-wrappers';
import { MultiUnitDateFieldWrapper } from './field-wrappers/multi-unit-date-field-wrapper';
import { MultiUnitPropertyRatesDateSelectorWrapper } from './field-wrappers/multi-unit-property-rates-date-selector-wrapper';
import { SingleUnitDateFieldWrapper } from './field-wrappers/single-unit-date-field-wrapper';
import { SharedUiFormWrapper } from './form-wrapper';
import { ReferrerContextProvider } from './infosite-referrer-context';
const TravelerSelectorWrapper = () => React.createElement(Experiment, {
  name: "Vrbex_traveler_selector"
}, React.createElement(ExperimentControl, null, React.createElement(SharedUiTravelersWrapper, {
  closeOnSubmit: false
})), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(SharedUiTravelersSelectorWrapper, null)));
const SingleLineDatePickerAndTravelerSelectorView = ({
  formatText,
  l10nKey
}) => React.createElement(EGDSLayoutFlex, {
  space: "three"
}, React.createElement(EGDSLayoutFlexItem, {
  grow: 4
}, React.createElement("div", null, React.createElement(Experiment, {
  name: "Rate_Calendar_Adjusted_Highlighting_Web"
}, React.createElement(ExperimentControl, null, React.createElement(MultiUnitDateFieldWrapper, {
  buttonText: formatText(l10nKey)
})), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(MultiUnitPropertyRatesDateSelectorWrapper, null))))), React.createElement(EGDSLayoutFlexItem, {
  grow: 3
}, React.createElement("div", null, React.createElement(TravelerSelectorWrapper, null))));
const MultiLinesDatePickerAndTravelerSelectorView = ({
  formatText,
  l10nKey
}) => React.createElement(React.Fragment, null, React.createElement("div", null, React.createElement(MultiUnitDateFieldWrapper, {
  buttonText: formatText(l10nKey)
})), React.createElement(EGDSSpacing, {
  margin: {
    block: 'three'
  }
}, React.createElement("div", null, React.createElement(TravelerSelectorWrapper, null))));
const MultiUnitForm = ({
  l10nKey
}) => {
  const {
    formatText
  } = useLocalization();
  return React.createElement(Experiment, {
    name: "MultiUnitVR_Master_MVP",
    logExposure: false
  }, React.createElement(ExperimentControl, null, React.createElement(SingleLineDatePickerAndTravelerSelectorView, {
    formatText: formatText,
    l10nKey: l10nKey
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(MultiLinesDatePickerAndTravelerSelectorView, {
    formatText: formatText,
    l10nKey: l10nKey
  })), React.createElement(ViewLarge, null, React.createElement(SingleLineDatePickerAndTravelerSelectorView, {
    formatText: formatText,
    l10nKey: l10nKey
  })))));
};
export const SharedUiMultiUnitForm = ({
  onChange
}) => {
  const location = useLocation();
  return React.createElement(ErrorBoundary, {
    label: "shared-ui-dated-form"
  }, React.createElement(ReferrerContextProvider, null, React.createElement(SharedUiFormWrapper, {
    action: location.pathname,
    onChange: onChange
  }, React.createElement(MultiUnitForm, {
    l10nKey: 'hotels.infosite.searchForm.availabilityButton.label'
  }))));
};
SharedUiMultiUnitForm.displayName = 'SharedUiMultiUnitForm';
export const SharedUiSingleUnitForm = ({
  onChange,
  onSubmit
}) => {
  const location = useLocation();
  return React.createElement(ReferrerContextProvider, null, React.createElement(SharedUiFormWrapper, {
    action: location.pathname,
    onChange: onChange,
    onSubmit: onSubmit
  }, React.createElement(EGDSLayoutFlex, {
    direction: "column",
    space: "three"
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement("div", null, React.createElement(SingleUnitDateFieldWrapper, null))))));
};
SharedUiSingleUnitForm.displayName = 'SharedUiSingleUnitForm';