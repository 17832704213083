import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { QueryRoute } from 'bernie-core';
import { Layer } from 'bernie-view';
import { SharedUiMap } from 'src/common/components/infosite/shared-ui/details/map-wrapper';
export const MapDialogWrapper = () => {
  const history = useHistory();
  const onCloseDialog = React.useCallback(() => history.goBack(), [history]);
  return React.createElement(QueryRoute, {
    query: {
      pwaDialog: 'map'
    }
  }, React.createElement(Layer, {
    id: "infosite-map",
    onDismiss: onCloseDialog
  }, React.createElement(SharedUiMap, {
    onClose: onCloseDialog
  })));
};
MapDialogWrapper.displayName = 'MapDialogWrapper';