import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { PropertyShareActions } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const ShareActionsWrapper = ({
  inputs,
  domain
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(ErrorBoundary, {
    label: "share.actions.wrapper"
  }, domain && React.createElement(PropertyShareActions, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery,
    domain: domain
  }));
};
export const SharedUiShareActionsWrapper = withSharedUiPropertyDetails(ShareActionsWrapper);
SharedUiShareActionsWrapper.displayName = 'SharedUiShareActionsWrapper';