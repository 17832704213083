import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { merge } from 'bernie-core';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { LodgingForm } from '@shared-ui/lodging-form';
import { RefTarget } from '@shared-ui/ref-context';
import { alwaysIgnoredKeys } from 'components/common/search-form';
import { ActionProvider } from './action-provider';
import { useFormInputs } from './use-form-inputs';
export const HiddenFields = ({
  searchCriteria
}) => {
  const [excludedFields, setExcludedFields] = React.useState(['latLong', 'regionId', 'x_pwa']);
  React.useEffect(() => {
    setExcludedFields([]);
  }, []);
  const hiddenFields = Object.keys(merge.withoutKeys([...alwaysIgnoredKeys, ...excludedFields, 'startDate', 'endDate', 'rooms']).merge({}, searchCriteria !== null && searchCriteria !== void 0 ? searchCriteria : {}));
  return React.createElement(React.Fragment, null, hiddenFields.map(field => {
    const value = (searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria[field]) || '';
    const disabled = value === '' || typeof (value === null || value === void 0 ? void 0 : value.length) === 'number' && value.length === 0;
    return React.createElement("input", {
      key: `hidden-field-${field}`,
      type: "hidden",
      name: field,
      value: value,
      disabled: disabled
    });
  }));
};
export const SharedUiFormWrapper = inject('currentHotel')(observer(({
  currentHotel,
  onChange,
  onSubmit,
  onError,
  action,
  children,
  formRef
}) => {
  const {
    hotelId,
    searchCriteria
  } = currentHotel || {};
  const formData = React.useMemo(() => ({
    method: 'GET',
    action: action || '',
    id: 'pdp-search-form'
  }), [action]);
  const inputs = hotelId && searchCriteria ? useFormInputs(hotelId, searchCriteria) : {};
  // Hidden fields need a few extra values for noJS
  const hiddenFieldsSearchCriteria = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.clone();
  if (!hotelId || !searchCriteria) {
    return null;
  }
  return React.createElement(EGDSSpacing, {
    margin: {
      blockend: 'two'
    }
  }, React.createElement("div", null, React.createElement(ActionProvider, {
    formAction: action || '',
    searchCriteria: searchCriteria,
    onChange: onChange,
    onSubmit: onSubmit,
    onError: onError
  }, React.createElement(RefTarget, {
    name: "FormWrapper",
    "data-stid": "rooms-and-rates"
  }, React.createElement(LodgingForm, {
    data: formData,
    inputs: inputs,
    formRef: formRef
  }, children, React.createElement(HiddenFields, {
    searchCriteria: hiddenFieldsSearchCriteria
  }))))));
}));