var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import { getPropertyOffersInput } from 'stores/adapters/bex-api/property-info/property-info-input-adapter';
export const withSharedUiPropertyOffers = SharedUiWrapper => {
  const PropertyOffersComponent = inject('currentHotel', 'context', 'multiItem', 'analyticsSearch')(observer(_a => {
    var _b, _c;
    var {
        currentHotel,
        analyticsSearch,
        context,
        multiItem
      } = _a,
      props = __rest(_a, ["currentHotel", "analyticsSearch", "context", "multiItem"]);
    const location = useLocation();
    const urlParameters = parse(location.search);
    const {
      dateless: datelessParam,
      startDate,
      d1,
      chkin
    } = urlParameters;
    const dateless = (_b = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria.isDateless) !== null && _b !== void 0 ? _b : datelessParam;
    const isDatedPdp = Boolean(!dateless && (startDate || d1 || chkin));
    const datesAreGoingToBeHydrated = isDatedPdp && !(currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria.startDate);
    if (!context || !(currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria) || !currentHotel.hotelId || datesAreGoingToBeHydrated) {
      return null;
    }
    const inputs = getPropertyOffersInput(context, currentHotel, urlParameters, (_c = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) !== null && _c !== void 0 ? _c : undefined, analyticsSearch);
    // We are intentionally overriding any skipSsr prop passed to the component.
    // Offers is slow and does not contain data for the Largest Contentful Paint element.
    // Any component that forgets to pass skipSsr true would cause a performance problem.
    return React.createElement(SharedUiWrapper, Object.assign({
      inputs: inputs
    }, props, {
      skipSsr: true
    }));
  }));
  PropertyOffersComponent.displayName = 'PropertyOffersComponent';
  return PropertyOffersComponent;
};