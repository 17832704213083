import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyPolicies } from '@shared-ui/lodging-property-details';
import { RefTarget } from '@shared-ui/ref-context';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const PoliciesWrapper = ({
  inputs
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(RefTarget, {
    name: "Policies"
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(PropertyPolicies, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  }))));
};
export const SharedUiPoliciesWrapper = withSharedUiPropertyDetails(PoliciesWrapper);
SharedUiPoliciesWrapper.displayName = 'SharedUiPoliciesWrapper';