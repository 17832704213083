import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core/cards';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyLevelOffersCard } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const SingleOfferPropertyLevelOffersCardWrapper = ({
  inputs
}) => React.createElement(ErrorBoundary, {
  label: "PropertyLevelOffersCard"
}, React.createElement(EGDSSpacing, {
  margin: {
    large: {
      block: 'three',
      inlinestart: 'three'
    }
  }
}, React.createElement(EGDSCard, {
  overflow: true
}, React.createElement(EGDSCardContentSection, {
  padded: false
}, React.createElement(PropertyLevelOffersCard, {
  inputs: inputs,
  skipSsr: true
})))));
SingleOfferPropertyLevelOffersCardWrapper.displayName = 'SingleOfferPropertyLevelOffersCardWrapper';
export const SharedUiSingleOfferPropertyLevelOffersCardWrapper = withSharedUiPropertyOffers(SingleOfferPropertyLevelOffersCardWrapper);