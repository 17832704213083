import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { SimpleLazyLoadWrapper } from 'src/common/components/common/lazy/simple-lazy-load-wrapper';
import { PropertyImportantInfo } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const ImportantInfoWrapper = ({
  inputs
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(PropertyImportantInfo, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })));
};
export const SharedUiImportantInfoWrapper = withSharedUiPropertyDetails(ImportantInfoWrapper);
SharedUiImportantInfoWrapper.displayName = 'SharedUiImportantInfoWrapper';