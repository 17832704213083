import * as React from 'react';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { SharedUiSaveTripItem } from './shared-ui-save-trip-item';
/**
 * Wrapper component for the SharedUiSaveTripItem component.
 * To be used by other template components.
 */
const SaveTripItemWrapper = ({
  inputs
}) => React.createElement(SharedUiSaveTripItem, {
  inputs: inputs
});
export const SharedUiSaveTripItemWrapper = withSharedUiPropertyDetails(SaveTripItemWrapper);
SaveTripItemWrapper.displayName = 'SharedUiSaveTripItemWrapper';