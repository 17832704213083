import * as React from 'react';
import { useLocation } from 'react-router';
import { ErrorBoundary } from 'bernie-view';
import { parse } from 'query-string';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { useRefTargetScroll } from '@shared-ui/ref-context';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { AvailabilityWizard } from 'components/infosite/rooms-and-rates/availability-wizard';
import { SharedUiMultiUnitForm } from 'components/infosite/shared-ui/form/shared-ui-forms';
const SharedUiMultiUnitFormWrapper = ({
  onUpdateResults
}) => {
  const {
    formatText
  } = useLocalization();
  const changeSearchA11yLabel = formatText('hotels.infosite.availabilityWizard.changeSearch.a11yLabel');
  return React.createElement(ErrorBoundary, {
    label: "hotel.information.rooms.availabilityWizard"
  }, React.createElement("div", {
    "data-stid": "section-hotel-search"
  }, React.createElement("h3", {
    className: "is-visually-hidden"
  }, changeSearchA11yLabel), React.createElement(SharedUiMultiUnitForm, {
    onChange: criteria => onUpdateResults(null, criteria, false)
  })));
};
export const SearchWizardComposer = ({
  isMultiItem,
  onUpdateResults,
  onUpdateResultsAvailabilityWizard
}) => {
  const availabilityWizardProps = {
    currentHotelOnly: true,
    onUpdateResults: onUpdateResultsAvailabilityWizard
  };
  const location = useLocation();
  const {
    scrollTo
  } = useRefTargetScroll();
  const urlParameters = parse(location.search);
  const {
    displayRate
  } = urlParameters;
  const focusOnRateCalendar = Boolean(displayRate);
  const SCROLL_OFFSETS = [{
    sectionName: 'navigation'
  }];
  React.useEffect(() => {
    if (focusOnRateCalendar) {
      scrollTo('Offers', SCROLL_OFFSETS);
    }
  }, []);
  if (isMultiItem) {
    return null;
  }
  return React.createElement(Experiment, {
    name: "MultiUnitVR_Master_MVP",
    logExposure: false
  }, React.createElement(ExperimentControl, null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(AvailabilityWizard, Object.assign({}, availabilityWizardProps))), React.createElement(ViewLarge, null, React.createElement(React.Fragment, null, React.createElement(SharedUiMultiUnitFormWrapper, {
    onUpdateResults: onUpdateResults
  }))))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SharedUiMultiUnitFormWrapper, {
    onUpdateResults: onUpdateResults
  })));
};
SearchWizardComposer.displayName = 'SearchWizardComposer';