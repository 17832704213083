const ExperimentVariants = {
  Control: 'Control',
  V1: 'V1',
  V2: 'V2'
};
const variantCardOptions = {
  headingOptions: {
    titleProps: {
      overflow: 'truncate-2-lines'
    },
    subTitleProps: {
      overflow: 'truncate-2-lines'
    }
  }
};
export const RecsModuleLayout = {
  [ExperimentVariants.Control]: {
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3
    }
  },
  [ExperimentVariants.V1]: {
    itemsVisible: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4
    },
    peek: {
      sm: false
    },
    cardOptions: variantCardOptions
  },
  [ExperimentVariants.V2]: {
    itemsVisible: {
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4
    },
    peek: {
      sm: false
    },
    cardOptions: variantCardOptions
  }
};