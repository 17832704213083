import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { updateSearch } from 'bernie-core';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { useLocalization } from '@shared-ui/localization-context';
import { LodgingOneKeyBurnSwitch } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const getHandleClick = (history, location, l10n) => isChecked => {
  updateSearch({
    history,
    location,
    newParams: {
      useRewards: String(isChecked)
    },
    replace: true
  });
  setTimeout(() => liveAnnounce(l10n === null || l10n === void 0 ? void 0 : l10n.formatText(isChecked ? 'common.pointsApplied' : 'common.pointsRemoved'), Level.POLITE), 500);
};
const LodgingOneKeyBurnSwitchWrapper = React.memo(({
  inputs
}) => {
  const history = useHistory();
  const location = history.location;
  const l10n = useLocalization();
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(LodgingOneKeyBurnSwitch, {
    inputs: inputs,
    handler: getHandleClick(history, location, l10n),
    skipSsr: true
  }));
});
LodgingOneKeyBurnSwitchWrapper.displayName = 'LodgingOneKeyBurnSwitchWrapper';
export const SharedUiLodgingOneKeyBurnSwitchWrapper = withSharedUiPropertyOffers(LodgingOneKeyBurnSwitchWrapper);