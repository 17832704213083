import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyTripSummary } from '@shared-ui/lodging-property-offers/';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const TripSummaryWrapper = ({
  inputs
}) => React.createElement(EGDSSpacing, {
  margin: {
    blockstart: 'three',
    blockend: 'three'
  }
}, React.createElement(PropertyTripSummary, {
  inputs: inputs,
  skipSsr: true,
  showBorder: true
}));
TripSummaryWrapper.displayName = 'SharedUiTripSummary';
export const SharedUiTripSummaryWrapper = withSharedUiPropertyOffers(TripSummaryWrapper);