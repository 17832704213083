import * as React from 'react';
import { inject } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { useBexApi } from '@shared-ui/bex-api-context';
import { ActivityRecommendations } from '@shared-ui/local-expert-activities';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { DateUtils } from 'utils/date-utils';
export const ActivityRecommendationsWrapper = inject('currentHotel', 'multiItem')(({
  currentHotel,
  multiItem
}) => {
  const {
    context: bexContext
  } = useBexApi();
  const {
    offerSearchData
  } = currentHotel || {};
  const startDate = (offerSearchData === null || offerSearchData === void 0 ? void 0 : offerSearchData.startDate) && DateUtils.parseDateString(offerSearchData.startDate);
  const endDate = (offerSearchData === null || offerSearchData === void 0 ? void 0 : offerSearchData.endDate) && DateUtils.parseDateString(offerSearchData.endDate);
  const dateRanges = startDate && endDate ? {
    startDate: startDate,
    endDate: endDate
  } : null;
  const isMultiItem = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  return React.createElement(ErrorBoundary, {
    label: "hotel.information.activities"
  }, React.createElement(React.Fragment, null, bexContext && (currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) && !isMultiItem && React.createElement(SimpleLazyLoadWrapper, {
    placeholder: React.createElement("div", {
      "data-stid": "recommended-activities__placeholder"
    })
  }, React.createElement(EGDSSpacing, {
    margin: {
      small: {
        inline: 'three'
      },
      medium: {
        inline: 'unset'
      }
    },
    padding: {
      blockstart: 'six'
    }
  }, React.createElement("div", null, React.createElement(ActivityRecommendations, {
    inputs: {
      destination: {
        regionId: String(currentHotel.hotelId),
        regionType: 'hotel'
      },
      dateRange: dateRanges,
      searchViewOptions: ['TRAVEL_DISTANCE_FROM_HOTEL']
    },
    skipSsr: true
  }))))));
});