/* eslint-disable max-lines */
import * as React from 'react';
import { inject } from 'mobx-react';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { useLocalization } from '@shared-ui/localization-context';
import { DateField, LodgingFormEvent } from '@shared-ui/lodging-form';
import { useRefContext } from '@shared-ui/ref-context';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { SearchCriteriaModel } from 'stores/models';
export const START_DATE_REF_NAME = 'startDate';
export const END_DATE_REF_NAME = 'endDate';
/* This date field wrapper is intended to be used for both Conventional Lodging and Multi-Unit VRs.
It's important to note that the Shared-UI DateField component uses an EGDS Date Picker under the covers
(as opposed to the EGDS Date Selector)
*/
export const MultiUnitDateFieldWrapper = inject('context', 'experiment', 'currentHotel')(({
  context,
  buttonText,
  onDismiss,
  closeOnSubmit = true,
  invalidDatesText
}) => {
  const {
    registerTarget
  } = useRefContext();
  // Register targets so that datepicker can be opened from different parts of the page
  const startDateRef = registerTarget(START_DATE_REF_NAME);
  const endDateRef = registerTarget(END_DATE_REF_NAME);
  const [placeholder, setPlaceholder] = React.useState(context === null || context === void 0 ? void 0 : context.localDateFormat);
  const {
    formatText
  } = useLocalization();
  React.useEffect(() => {
    setPlaceholder(formatText('hotels.search.form.toDate.placeholder'));
  }, []);
  const startLableLocalized = isVrbo(context) ? 'dateSelector.startDate.label' : 'hotels.search.form.fromDateHyphen.label';
  const endLableLocalized = isVrbo(context) ? 'dateSelector.endDate.label' : 'hotels.search.form.toDateHyphen.label';
  const dateFieldData = React.useMemo(() => {
    const output = {
      startId: 'hotels-check-in',
      startLabel: formatText(startLableLocalized),
      startName: 'startDate',
      startPlaceholder: placeholder || '',
      startInvalid: '',
      endId: 'hotels-check-out',
      endLabel: formatText(endLableLocalized),
      endName: 'endDate',
      endPlaceholder: placeholder || '',
      endInvalid: '',
      buttonText,
      daysBookableInAdvance: SearchCriteriaModel.NUM_OF_DAYS_BOOKABLE_IN_ADVANCE,
      midnightBookingOffset: SearchCriteriaModel.MIDNIGHT_BOOKING_OFFSET_IN_HOURS,
      __typename: 'LodgingFormDateField',
      validations: [{
        __typename: 'StartDateRequiredValidation',
        error: invalidDatesText || formatText('hotels.search.validation.invalidFromDate.text'),
        events: [LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT]
      }, {
        __typename: 'EndDateRequiredValidation',
        error: invalidDatesText || formatText('hotels.search.validation.invalidToDate.text'),
        events: [LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT]
      }, {
        __typename: 'DateOrderValidation',
        error: formatText('hotels.search.validation.invalidDateRange.text'),
        events: [LodgingFormEvent.ON_LOAD, LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT]
      }, ...(isVrbo(context) ? [] : [{
        // TODO: Confirm Vrbo MUVRs don't need this validation as well. (This check was written when vrbo only had SUVR)
        __typename: 'MaxDateRangeValidation',
        // Explicitly set as a string literal
        error: formatText('hotels.search.validation.invalidDateDateRangeLength.text'),
        maxDateRange: 28,
        events: [LodgingFormEvent.ON_LOAD, LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT]
      }])]
    };
    return output;
  }, [buttonText, formatText, invalidDatesText, placeholder]);
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(DateField, {
    fieldName: "dates",
    data: dateFieldData,
    closeOnSubmit: closeOnSubmit,
    onDismiss: onDismiss,
    isOpenOverride: true,
    startDateRef: startDateRef,
    endDateRef: endDateRef
  })), React.createElement(ViewLarge, null, React.createElement(DateField, {
    fieldName: "dates",
    data: dateFieldData,
    closeOnSubmit: closeOnSubmit,
    startDateRef: startDateRef,
    endDateRef: endDateRef
  })));
});