import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { number, object, optional, safeParse } from 'valibot';
import { SharedUiAboutThisPropertyWrapper } from 'components/infosite/shared-ui/details/about-this-property-wrapper';
export const AboutThisPropertyWrapper = ({
  templateComponent
}) => {
  var _a;
  const configSchema = optional(object({
    columns: optional(number())
  }));
  const result = safeParse(configSchema, templateComponent.config);
  if (!result.success) {
    return null;
  }
  const {
    columns
  } = (_a = result.output) !== null && _a !== void 0 ? _a : {};
  return React.createElement(EGDSSpacing, {
    margin: {
      medium: {
        blockstart: 'six'
      },
      small: {
        blockstart: 'six'
      }
    }
  }, React.createElement("div", null, React.createElement(SharedUiAboutThisPropertyWrapper, {
    columns: columns
  })));
};