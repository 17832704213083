import React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { useStores } from 'src/common/stores/use-stores';
import { SocialShareButton } from '@shared-ui/retail-social-share-social-share-button';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
const SocialShareButtonWrapper = ({
  inputs
}) => {
  var _a;
  const {
    page: {
      pageId
    }
  } = useStores();
  const socialShareButtonInputs = {
    shareContext: {
      propertyId: inputs.propertyId,
      destinationId: (_a = inputs.searchCriteria) === null || _a === void 0 ? void 0 : _a.primary.destination.regionId,
      pageId
    }
  };
  return React.createElement(ErrorBoundary, {
    label: "social.share.button.wrapper"
  }, React.createElement(SocialShareButton, {
    inputs: socialShareButtonInputs
  }));
};
export const SharedUiSocialShareButtonWrapper = withSharedUiPropertyDetails(SocialShareButtonWrapper);
SharedUiSocialShareButtonWrapper.displayName = 'SharedUiSocialShareButtonWrapper';