import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePropertyDetailsQueryCSRExperiment } from '../shared-ui/details/use-property-details-query';
export const useQueryParamStoredPdpImages = () => {
  const search = useLocation().search;
  usePropertyDetailsQueryCSRExperiment(true);
  return useMemo(() => {
    var _a;
    const imageUrls = (_a = new URLSearchParams(search).get('pdpImageUrls')) === null || _a === void 0 ? void 0 : _a.split(',');
    return imageUrls === null || imageUrls === void 0 ? void 0 : imageUrls.map(url => ({
      url,
      description: ''
    }));
    // we only want to read the query params once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export const useQueryParamStoredPdpPropertyName = () => {
  const search = useLocation().search;
  return new URLSearchParams(search).get('propertyName');
};