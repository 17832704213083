import * as React from 'react';
import { EGDSFigureAspectRatioType } from '@egds/react-core/images';
import { EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { PropertySummaryMapTrigger, PropertySummaryNearByPois } from '@shared-ui/lodging-property-details';
import { IdentifierType, LocationSection } from '@shared-ui/retail-product-location-section';
import { ViewLarge, ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
export const LocationSectionWrapper = ({
  inputs,
  children,
  onClickMap,
  skipSsr
}) => {
  const mapComponent = React.createElement(PropertySummaryMapTrigger, {
    inputs: inputs,
    onClickMap: onClickMap,
    aspectRatio: EGDSFigureAspectRatioType.R21_9,
    skipSsr: skipSsr
  }, children);
  return React.createElement(Experiment, {
    name: "PDP_LOCATION_SECTION_MIGRATION_WEB"
  }, React.createElement(ExperimentControl, null, React.createElement(EGDSLayoutFlexItem, null, React.createElement("div", null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, React.createElement("div", {
    "data-stid": "map-image-link"
  }, React.createElement(EGDSSpacing, {
    padding: {
      small: {
        blockend: 'six'
      }
    }
  }, mapComponent)), React.createElement("div", {
    "data-stid": "location-summary"
  }, React.createElement(EGDSSpacing, {
    padding: {
      small: {
        blockend: 'six'
      }
    }
  }, React.createElement(PropertySummaryNearByPois, {
    inputs: inputs,
    skipSsr: skipSsr
  }))))), React.createElement(ViewMedium, null, React.createElement(EGDSLayoutGrid, {
    space: "three",
    columns: 2
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", {
    "data-stid": "map-image-link"
  }, mapComponent)), React.createElement(EGDSLayoutGridItem, null, React.createElement("div", {
    "data-stid": "location-summary"
  }, React.createElement(PropertySummaryNearByPois, {
    inputs: inputs,
    skipSsr: skipSsr
  }))))), React.createElement(ViewLarge, null, React.createElement(EGDSLayoutGrid, {
    space: "three",
    columns: 2
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", {
    "data-stid": "map-image-link"
  }, mapComponent)), React.createElement(EGDSLayoutGridItem, null, React.createElement("div", {
    "data-stid": "location-summary"
  }, React.createElement(Experiment, {
    name: "PEXO_PDP_Location_summary_layout_improvement"
  }, React.createElement(ExperimentControl, null, React.createElement(PropertySummaryNearByPois, {
    inputs: inputs,
    skipSsr: skipSsr
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSSpacing, {
    margin: {
      blockstart: 'eight'
    }
  }, React.createElement(PropertySummaryNearByPois, {
    inputs: inputs,
    skipSsr: skipSsr
  })))))))))))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSLayoutGrid, {
    space: "three",
    columns: 1
  }, React.createElement(LocationSection, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: IdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputs.searchCriteria
        }
      }
    },
    isHorizontalLayout: true
  }, children))));
};