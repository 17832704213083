import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { DiscoveryPriceStrategy } from 'src/common/__generated__/api';
import { ConfigurationIdentifiers } from 'src/common/constants/configuration-identifiers';
import { EgRecsPlacementIds } from 'src/common/constants/eg-recs-placements';
import { getShoppingContextInput } from 'src/common/stores/adapters';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { usePropertyCarouselContexts } from 'src/common/utils/carousel-utils';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { DiscoveryCardContentSize, DiscoveryContainerType, DiscoveryOfferingType, DiscoveryRecommendationInputContext, DiscoveryRecommendationStrategy, LineOfBusinessDomain, RecommendationsModule } from '@shared-ui/retail-recommendation-module';
import { SimpleLazyLoadWrapper } from '../../common/lazy/simple-lazy-load-wrapper';
import { RecsModuleLayout } from './module-layout';
export const RecommendationsModuleWrapper = inject('currentHotel', 'multiItem')(observer(props => {
  var _a, _b;
  const {
    context,
    searchCriteria,
    availableRooms
  } = usePropertyCarouselContexts();
  const multiItemContext = (_a = props.multiItem) === null || _a === void 0 ? void 0 : _a.multiItemContext;
  const shoppingContext = getShoppingContextInput(multiItemContext);
  const propertySearchCriteria = adaptSearchCriteria(context, searchCriteria);
  const inputProps = {
    inputs: {
      propertyId: props.currentHotel && props.currentHotel.hotelId ? props.currentHotel.hotelId.toString() : '',
      shoppingContext
    }
  };
  const primarySearchCriteria = propertySearchCriteria.primary;
  const dateRange = primarySearchCriteria.dateRange;
  const rooms = primarySearchCriteria.rooms.map(room => {
    var _a;
    return Object.assign(Object.assign({}, room), {
      adults: room.adults,
      children: (_a = room.children) === null || _a === void 0 ? void 0 : _a.map(child => child === null || child === void 0 ? void 0 : child.age)
    });
  });
  const checkInDate = dateRange === null || dateRange === void 0 ? void 0 : dateRange.checkInDate;
  const checkOutDate = dateRange === null || dateRange === void 0 ? void 0 : dateRange.checkOutDate;
  if (!checkInDate || !checkOutDate) {
    return null;
  }
  const commonInputs = {
    contentSize: DiscoveryCardContentSize.LONG_FORM_FEATURED,
    offeringType: DiscoveryOfferingType.PRODUCT,
    strategy: DiscoveryRecommendationStrategy.ALTERNATIVE,
    containerType: DiscoveryContainerType.CAROUSEL,
    priceStrategy: DiscoveryPriceStrategy.LIVE,
    input: [DiscoveryRecommendationInputContext.PRODUCT],
    recommendationContext: {
      outputLineOfBusiness: LineOfBusinessDomain.LODGING,
      pageId: 'HIS',
      lodging: {
        propertyId: String(inputProps.inputs.propertyId),
        checkin: checkInDate,
        checkout: checkOutDate,
        rooms: rooms,
        searchCriteria: {
          selections: (_b = propertySearchCriteria.secondary) === null || _b === void 0 ? void 0 : _b.selections
        }
      }
    },
    placementId: EgRecsPlacementIds.PROPERTY_RECS_PLACEMENT_ID
  };
  return React.createElement(Experiment, {
    name: "SmaSh_Property_Recs_New_Carousel"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement("div", {
    "data-stid": "recommendations-module"
  }, React.createElement(Experiment, {
    name: "Smart_Shopping_Property_Recs_Carousel_MLP"
  }, React.createElement(ExperimentControl, null, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(RecommendationsModule, {
    inputs: Object.assign(Object.assign({}, commonInputs), {
      configurationIdentifier: availableRooms ? ConfigurationIdentifiers.LODGING_DATED_PDP_CAROUSEL_FEATURED_HEADER_CHECK : ConfigurationIdentifiers.LODGING_DATED_PDP_LODGING_FALLBACK
    }),
    layoutOptions: RecsModuleLayout.Control
  }))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(RecommendationsModule, {
    inputs: Object.assign(Object.assign({}, commonInputs), {
      configurationIdentifier: availableRooms ? ConfigurationIdentifiers.LODGING_DATED_PDP_CAROUSEL_HIGHLIGHTS : ConfigurationIdentifiers.LODGING_DATED_PDP_CAROUSEL_HIGHLIGHTS_FALLBACK
    }),
    layoutOptions: RecsModuleLayout.V1
  }))), React.createElement(ExperimentVariant, {
    bucket: 2
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(RecommendationsModule, {
    inputs: Object.assign(Object.assign({}, commonInputs), {
      configurationIdentifier: availableRooms ? ConfigurationIdentifiers.LODGING_DATED_PDP_CAROUSEL_HIGHLIGHTS_NO_SUBTITLE : ConfigurationIdentifiers.LODGING_DATED_PDP_CAROUSEL_HIGHLIGHTS_NO_SUBTITLE_FALLBACK
    }),
    layoutOptions: RecsModuleLayout.V2
  })))))));
}));
export const SharedUiRecommendationsModuleWrapper = RecommendationsModuleWrapper;
SharedUiRecommendationsModuleWrapper.displayName = 'SharedUiRecommendationsModuleWrapper';