import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PropertyStickyBookBar } from '@shared-ui/lodging-property-offers';
import { useRefContext } from '@shared-ui/ref-context';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
export const StickyBookBarWrapper = inject('currentHotel', 'multiItem')(observer(({
  inputs,
  multiItem
}) => {
  var _a;
  // Used for H+H use case
  if ((_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.cartInfo) === null || _a === void 0 ? void 0 : _a.id) {
    return null;
  }
  const {
    getTargets
  } = useRefContext();
  const refOffersTarget = getTargets(['Offers']);
  return React.createElement(PropertyStickyBookBar, {
    inputs: inputs,
    focusTargetRef: "Offers",
    skipSsr: true,
    refTargets: refOffersTarget
  });
}));
StickyBookBarWrapper.displayName = 'StickyBookBarWrapper';
export const SharedUiStickyBookBarWrapper = withSharedUiPropertyOffers(StickyBookBarWrapper);