import * as React from 'react';
import { inject } from 'mobx-react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { PropertyAmenityDescription, PropertyDetailedAmenities } from '@shared-ui/lodging-property-details';
import { RefTarget } from '@shared-ui/ref-context';
import { AccessibilityAttributes, IdentifierType } from '@shared-ui/retail-product-accessibility-attributes';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
import { PRODUCT_DETAILS_ACCESSIBILITY_BATCH_KEY } from 'constants/batchKeys';
const AmenityDescriptionWrapper = inject('context')(({
  inputs,
  context
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(React.Fragment, null, React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(RefTarget, {
    name: "Amenities"
  }, React.createElement(SimpleLazyLoadWrapper, null, isVrbo(context) ? React.createElement(PropertyDetailedAmenities, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  }) : React.createElement(PropertyAmenityDescription, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })))), React.createElement(EGDSSpacing, null, React.createElement(RefTarget, {
    name: "Accessibility"
  }, React.createElement(Experiment, {
    name: "CID_Accessibility_Section_Breakout"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(AccessibilityAttributes, {
    inputs: {
      productIdentifier: {
        id: inputs.propertyId,
        type: IdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: inputs.searchCriteria
        }
      }
    },
    batchKey: PRODUCT_DETAILS_ACCESSIBILITY_BATCH_KEY
  })))))));
});
export const SharedUiAmenityDescriptionWrapper = withSharedUiPropertyDetails(AmenityDescriptionWrapper);
SharedUiAmenityDescriptionWrapper.displayName = 'SharedUiAmenityDescriptionWrapper';