export const SEARCH_ID_PARAM = 'searchId';
export const adjustSearchCriteriaWithSearchId = (searchCriteria, newSearchId) => {
  var _a, _b, _c;
  const missingSearchId = !((_b = (_a = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.secondary) === null || _a === void 0 ? void 0 : _a.selections) === null || _b === void 0 ? void 0 : _b.some(selection => selection.id === SEARCH_ID_PARAM));
  const shouldAddNewSearchId = searchCriteria && missingSearchId && newSearchId;
  return shouldAddNewSearchId ? Object.assign(Object.assign({}, searchCriteria), {
    secondary: Object.assign(Object.assign({}, searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.secondary), {
      selections: [...(((_c = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.secondary) === null || _c === void 0 ? void 0 : _c.selections) || []), {
        id: SEARCH_ID_PARAM,
        value: newSearchId
      }]
    })
  }) : searchCriteria;
};