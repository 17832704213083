import * as React from 'react';
import { inject } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { useExperiment } from '@shared-ui/experiment-context';
import { PropertyLevelOffersCard } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PropertyLevelOffersCardWrapper = inject('multiItem')(({
  inputs,
  multiItem
}) => {
  const {
    bucket: isModularised
  } = useExperiment('PDP_MODULARISED_LOCATION_SECTION').exposure;
  const isMultiItem = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  return React.createElement(ErrorBoundary, {
    label: "PropertyLevelOffersCard"
  }, React.createElement(EGDSSpacing, {
    margin: {
      small: {
        inline: 'three',
        blockend: 'three'
      },
      medium: {
        inline: 'unset',
        blockend: 'three'
      }
    },
    padding: isModularised ? undefined : {
      blockstart: 'three'
    }
  }, React.createElement("div", null, React.createElement(PropertyLevelOffersCard, {
    inputs: inputs,
    skipSsr: true,
    isMultiItem: isMultiItem
  }))));
});
PropertyLevelOffersCardWrapper.displayName = 'PropertyLevelOffersCardWrapper';
export const SharedUiPropertyLevelOffersCardWrapper = withSharedUiPropertyOffers(PropertyLevelOffersCardWrapper);