import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { updateSearch } from 'bernie-core';
import { parse } from 'query-string';
import { PropertyFilterAmenity } from 'src/common/__generated__/api';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { DateUtils } from 'src/common/utils/date-utils';
import { isPetsAmenityFilterPresent } from 'src/common/utils/pets-utils';
import { useLocalization } from '@shared-ui/localization-context';
import { ContactHost } from '@shared-ui/retail-lodging-contact-host';
import { IdentifierType } from '@shared-ui/retail-product-answering-traveller-questions';
import { getPropertyOffersInput } from 'stores/adapters/bex-api/property-info/property-info-input-adapter';
import { InfositeQueryAdapter } from 'stores/adapters/infosite-query-adapter';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const ContactHostWrapper = inject('context', 'currentHotel', 'analyticsSearch')(observer(({
  context,
  currentHotel,
  analyticsSearch,
  inputs,
  prominence
}) => {
  const l10n = useLocalization();
  const history = useHistory();
  const location = useLocation();
  const {
    hotelId,
    searchCriteria
  } = currentHotel || {};
  const urlParameters = parse(location.search);
  if (!context || !(currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria) || !currentHotel.hotelId) {
    return null;
  }
  const offersInputs = getPropertyOffersInput(context, currentHotel, urlParameters, undefined, analyticsSearch);
  const updateFilter = payload => {
    var _a, _b, _c, _d;
    const currentSearchCriteria = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria;
    if (currentHotel && currentSearchCriteria) {
      const {
        dateChange,
        rooms,
        petsIncluded,
        dateRange
      } = payload;
      const startDate = DateUtils.parseStringDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.selectedStartDate);
      const endDate = DateUtils.parseStringDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.selectedEndDate);
      const params = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, currentSearchCriteria), {
        rooms: rooms || inputs.searchCriteria.primary.rooms
      }), rooms && {
        amenities: petsIncluded ? PropertyFilterAmenity.PETS : null,
        // eslint-disable-next-line camelcase
        house_rules_group: petsIncluded ? 'pets_allowed' : null
      }), {
        localDateFormat: context === null || context === void 0 ? void 0 : context.localDateFormat
      }), dateChange && {
        startDate: startDate,
        endDate: endDate,
        _startDate: startDate,
        _endDate: endDate
      });
      const newParams = InfositeQueryAdapter.parseOut(params, l10n);
      const adults = (_b = (_a = params.rooms) === null || _a === void 0 ? void 0 : _a.reduce((count, room) => count + (room.adults || 0), 0)) !== null && _b !== void 0 ? _b : 0;
      const children = (_d = (_c = params.rooms) === null || _c === void 0 ? void 0 : _c.flatMap((room, roomIndex) => {
        var _a, _b;
        return (_b = (_a = room.children) === null || _a === void 0 ? void 0 : _a.map(({
          age
        }) => `${roomIndex + 1}_${age}`)) !== null && _b !== void 0 ? _b : [];
      })) !== null && _d !== void 0 ? _d : [];
      setTimeout(() => updateSearch({
        location,
        history,
        newParams,
        replace: true
      }), 50);
      currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setOfferData(Object.assign({
        adults,
        children: children.join(','),
        petsIncluded: petsIncluded
      }, dateChange && {
        startDate: startDate,
        endDate: endDate
      }));
      currentHotel.showPetsOnSingleOffer = petsIncluded;
    }
  };
  const contactHostInput = {
    primaryPropertyCriteriaInput: {
      rooms: inputs.searchCriteria.primary.rooms,
      destination: {
        propertyIds: [inputs.propertyId]
      },
      dateRange: inputs.searchCriteria.primary.dateRange
    },
    petsIncluded: isPetsAmenityFilterPresent(context, currentHotel)
  };
  const contactHostInputWithProminence = Object.assign({
    prominence,
    inquiryFormInput: contactHostInput
  }, offersInputs);
  const ref = React.useRef(null);
  const travellerQnAInputs = React.useMemo(() => ({
    productIdentifier: {
      id: (hotelId === null || hotelId === void 0 ? void 0 : hotelId.toString()) || '',
      type: IdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: context && adaptSearchCriteria(context, searchCriteria, undefined, undefined, analyticsSearch)
      }
    }
  }), [hotelId, searchCriteria, context]);
  return React.createElement(ContactHost, {
    inputs: contactHostInputWithProminence,
    answeringTravellerQuestionInputs: travellerQnAInputs,
    onFieldsChangeCallback: updateFilter,
    triggerRef: ref,
    skipSsr: true
  });
}));
export const SharedUiContactHostWrapper = withSharedUiPropertyOffers(ContactHostWrapper);
SharedUiContactHostWrapper.displayName = 'SharedUiContactHostWrapper';