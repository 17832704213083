import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyDirectFeedbackPromptId } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from '../shared-ui/details/shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from '../shared-ui/details/use-property-details-query';
const Component = ({
  inputs
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      blockstart: 'four'
    }
  }, React.createElement("div", null, React.createElement(PropertyDirectFeedbackPromptId, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery,
    withHorizontalLine: true,
    align: "center"
  })));
};
const PropertyAboutThisPropertyDirectFeedback = React.memo(({
  inputs
}) => React.createElement(Component, {
  inputs: inputs
}));
export const PropertyAboutThisPropertyDirectFeedbackWrapper = withSharedUiPropertyDetails(PropertyAboutThisPropertyDirectFeedback);
PropertyAboutThisPropertyDirectFeedbackWrapper.displayName = 'PropertyAboutThisPropertyDirectFeedbackWrapper';