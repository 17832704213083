/* eslint-disable */
const throttle = require('lodash.throttle');
/* global document, window */
const invariant = require('invariant');
const THROTTLE_DELAY = 300;
const HitTestResult = {
  ABOVE: 'above',
  BELOW: 'below',
  INTERSECT: 'intersect',
  ABOVE_TOP: 'above top'
};
const defaultOptions = {
  offset: {
    top: 0,
    bottom: 0
  }
};
export default class ViewportListener {
  constructor() {
    this.subscriptions = {};
    this.eventListeners = {};
    this.onScroll = throttle(this.onScroll, THROTTLE_DELAY, {
      trailing: true
    });
    this.onResize = throttle(this.onResize, THROTTLE_DELAY, {
      trailing: true
    });
  }
  // listener.subscribe(parentSelector, elementSelector, {didEnter, didExit, isAboveTop, isBelowTop, isAbove, isBelow});
  // returns false for invalid parentSelector
  subscribe(parentSelector, el, events = {}, options = {}) {
    invariant(el, 'You have to provide a valid element to observe');
    const parentElement = this._getParentElement(parentSelector);
    if (!parentElement) {
      return false;
    }
    const offset = options.offset || defaultOptions.offset;
    const parentSelectorListeners = this.subscriptions[parentSelector];
    const boundingBox = this._calculateBoundingBox(el, parentElement, offset, options);
    const childElementCount = el.childElementCount;
    const listener = Object.assign(Object.assign({}, events), {
      boundingBox,
      el,
      offset,
      childElementCount,
      options
    });
    if (parentSelectorListeners) {
      // insert in order of positioning from "top"
      this._addOrderedListener(parentSelectorListeners, boundingBox, listener);
    } else {
      this.subscriptions[parentSelector] = [listener];
      this._addQuerySelectorScrollListener(parentSelector, parentElement);
    }
    // check viewport intersection immediately
    if (listener.didEnter) {
      const viewport = this._getViewport(parentElement);
      if (this._hitTest(viewport, boundingBox) === HitTestResult.INTERSECT) {
        listener.didEnter();
        listener.entryTimestamp = Date.now();
        if (listener.options.triggerOnce) {
          this.unsubscribe(el, parentSelector);
        }
      }
    }
    // check viewport non-intersection immediately
    if (listener.didExit) {
      const viewport = this._getViewport(parentElement);
      if (this._hitTest(viewport, boundingBox) !== HitTestResult.INTERSECT) {
        listener.didExit();
      }
    }
    return listener;
  }
  unsubscribe(el, parentSelector) {
    const listeners = this.subscriptions[parentSelector];
    if (listeners) {
      const index = listeners.findIndex(listener => listener.el === el);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
      if (listeners.length === 0) {
        this.unsubscribeAll(parentSelector);
      }
    }
  }
  unsubscribeAll(querySelector = undefined) {
    if (querySelector) {
      this._removeQuerySelectorScrollListener(querySelector);
      delete this.subscriptions[querySelector];
    } else {
      Object.keys(this.subscriptions).forEach(qs => this.unsubscribeAll(qs));
    }
  }
  // helpers
  _getParentElement(parentSelector) {
    return parentSelector === 'window' ? window : document.querySelector(parentSelector);
  }
  _addOrderedListener(parentSelectorListeners, boundingBox, listener) {
    if (parentSelectorListeners) {
      let i = 0;
      while (i < parentSelectorListeners.length && parentSelectorListeners[i].boundingBox.top < boundingBox.top) {
        i += 1;
      }
      parentSelectorListeners.splice(i, 0, listener);
    }
  }
  _addQuerySelectorScrollListener(parentSelector, parentElement) {
    const scrollListener = () => this.onScroll(parentSelector, parentElement);
    parentElement.addEventListener('scroll', scrollListener);
    const resizeListener = () => this.onResize(parentSelector, parentElement);
    parentElement.addEventListener('resize', resizeListener);
    this.eventListeners[parentSelector] = {
      parentElement,
      scrollListener,
      resizeListener
    };
  }
  _removeQuerySelectorScrollListener(parentSelector) {
    if (this.eventListeners[parentSelector]) {
      const {
        parentElement,
        scrollListener,
        resizeListener
      } = this.eventListeners[parentSelector];
      parentElement.removeEventListener('scroll', scrollListener);
      parentElement.removeEventListener('resize', resizeListener);
      delete this.eventListeners[parentSelector];
    }
  }
  // scroll listening + hit testing
  // NOTE: vertical hit testing only.
  onScroll(parentSelector, el) {
    const viewport = this._getViewport(el);
    const subscribers = this.subscriptions[parentSelector];
    const timestamp = Date.now();
    const deferredCallbacks = [];
    const removeSubscriberIndex = [];
    let i;
    // don't iterate everything, just the items that fall into the top/bottom bounds
    if (subscribers && subscribers.length > 0) {
      for (i = 0; i < subscribers.length; i++) {
        const subscriber = this._updateSubscriber(subscribers[i], el);
        const location = this._hitTest(viewport, subscriber.boundingBox);
        if (location === HitTestResult.ABOVE_TOP) {
          if (subscriber.isAboveTop) {
            subscriber.isAboveTop();
          }
        }
        if (location === HitTestResult.ABOVE) {
          if (subscriber.isAbove) {
            subscriber.isAbove();
          }
          this.checkForExit(subscriber, timestamp, deferredCallbacks);
          if (subscriber.isAbove && subscriber.options && subscriber.options.triggerOnce) {
            removeSubscriberIndex.push(i);
          }
          continue;
        }
        if (location === HitTestResult.INTERSECT) {
          if (subscriber.isBelowTop) {
            subscriber.isBelowTop();
          }
        }
        if (location === HitTestResult.BELOW) {
          if (subscriber.isBelow) {
            subscriber.isBelow();
          }
          continue;
        }
        // location === 'intersects'
        if (subscriber.didEnter) {
          if (!subscriber.entryTimestamp) {
            deferredCallbacks.push(() => subscriber.didEnter());
          }
          subscriber.entryTimestamp = timestamp;
          if (subscriber.options && subscriber.options.triggerOnce) {
            removeSubscriberIndex.push(i);
          }
        }
      }
      subscribers.forEach(subscriber => {
        this.checkForExit(subscriber, timestamp, deferredCallbacks);
      });
      for (i = removeSubscriberIndex.length - 1; i > -1; i--) {
        subscribers.splice(removeSubscriberIndex[i], 1);
      }
    }
    // removing concurrency issues
    deferredCallbacks.forEach(callback => callback());
  }
  checkForExit(subscriber, timestamp, deferredCallbacks) {
    if (subscriber.entryTimestamp && subscriber.entryTimestamp !== timestamp && subscriber.didExit) {
      deferredCallbacks.push(() => subscriber.didExit());
      delete subscriber.entryTimestamp;
    }
  }
  onResize(parentSelector, el) {
    const reorderedSubscriptions = {};
    Object.keys(this.subscriptions).forEach(key => {
      const keyedListeners = [];
      const parentElement = this._getParentElement(key);
      this.subscriptions[key].forEach(listener => {
        const boundingBox = this._calculateBoundingBox(listener.el, parentElement, listener.offset);
        this._addOrderedListener(keyedListeners, boundingBox, Object.assign(Object.assign({}, listener), {
          boundingBox
        }));
      });
      reorderedSubscriptions[key] = keyedListeners;
    });
    this.subscriptions = reorderedSubscriptions;
    this.onScroll(parentSelector, el);
  }
  _getViewport(el) {
    if (!el) {
      return {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      };
    }
    return el === window ? {
      left: window.scrollX,
      top: window.scrollY,
      right: window.scrollX + window.innerWidth,
      bottom: window.scrollY + window.innerHeight
    } : {
      left: el.scrollLeft,
      top: el.scrollTop,
      right: el.scrollLeft + el.clientWidth,
      bottom: el.scrollTop + el.clientHeight
    };
  }
  _hitTest(viewport, elementBoundingBox) {
    if (elementBoundingBox.top < viewport.top) {
      // elementBoundingBox above hit area
      if (elementBoundingBox.bottom < viewport.top) {
        return HitTestResult.ABOVE;
      }
      // half intersects (bottom is inside hit area or beyond)
      return HitTestResult.ABOVE_TOP;
    }
    // box is below the bottom of hit area
    else if (elementBoundingBox.top > viewport.bottom) {
      return HitTestResult.BELOW;
    }
    // all other conditions are a hit: (elementBoundingBox.top >= viewport.top && elementBoundingBox.top <= viewport.bottom) && xbox.bottom >= viewport.top
    return HitTestResult.INTERSECT;
  }
  _calculateBoundingBox(el, parentElement, offset = {
    top: 0,
    bottom: 0
  }, options = {}) {
    const elementRect = el.getBoundingClientRect();
    const scroll = this._getViewport(parentElement);
    const top = elementRect.top - offset.top + scroll.top;
    const bottom = (options === null || options === void 0 ? void 0 : options.extendBoundingBoxToBottomOfPage) ? document.documentElement.scrollHeight : elementRect.bottom + offset.bottom + scroll.top;
    const left = elementRect.left + scroll.left;
    const boundingBox = Object.assign(Object.assign({}, elementRect), {
      top,
      y: top,
      bottom,
      height: bottom - top,
      left,
      x: left,
      right: elementRect.right + scroll.left
    });
    return boundingBox;
  }
  _updateSubscriber(subscriber, parentElement) {
    subscriber.childElementCount = subscriber.el.childElementCount;
    subscriber.boundingBox = this._calculateBoundingBox(subscriber.el, parentElement, subscriber.offset);
    return subscriber;
  }
}