import * as React from 'react';
import { inject } from 'mobx-react';
// @ts-ignore
import * as isEqual from 'lodash.isequal';
import { PropertySearchLink } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PropertySearchLinkWrapper = inject('currentHotel')(({
  currentHotel,
  inputs
}) => {
  const previousInputs = React.useRef(inputs);
  const [hasInputUpdated, setHasInputUpdated] = React.useState(false);
  React.useEffect(() => {
    if (!isEqual(previousInputs.current, inputs)) {
      previousInputs.current = inputs;
      setHasInputUpdated(true);
    }
  }, [inputs]);
  return React.createElement(PropertySearchLink, {
    inputs: inputs,
    skipSsr: true,
    isNavigationFromSRP: currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.isNavigationFromSRP,
    showLinkLabel: false,
    hasInputUpdated: hasInputUpdated
  });
});
PropertySearchLinkWrapper.displayName = 'SharedUiPropertySearchLinkWrapper';
export const SharedUiPropertySearchLinkWrapper = withSharedUiPropertyOffers(PropertySearchLinkWrapper);