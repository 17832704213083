import * as React from 'react';
import { UitkSpacing } from '@egds/react-core/spacing';
import { useStores } from 'src/common/stores/use-stores';
import { IdentifierType, RandomAccessOne } from '@shared-ui/retail-product-random-access-one';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
export const CheckInOutWrapper = () => {
  var _a;
  const {
    context,
    currentHotel
  } = useStores();
  const propertyId = (_a = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) === null || _a === void 0 ? void 0 : _a.toString();
  const searchCriteria = context && currentHotel && adaptSearchCriteria(context, currentHotel.searchCriteria);
  if (!propertyId) {
    return null;
  }
  return React.createElement(UitkLayoutGrid, {
    columns: {
      small: 1,
      medium: 1,
      large: 4
    }
  }, React.createElement(UitkLayoutGridItem, {
    colSpan: {
      small: 1,
      medium: 1,
      large: 3
    }
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, React.createElement("div", null, React.createElement(RandomAccessOne, {
    inputs: {
      featureId: 'check-in-check-out',
      productIdentifier: {
        id: propertyId,
        type: IdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: searchCriteria
        }
      }
    },
    batchKey: "default-product-details-experience-batch-key"
  })))));
};