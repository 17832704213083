import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PropertyActivitiesCrossSell } from '@shared-ui/retail-lodging-property-activities-cross-sell';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
export const PropertyActivitiesCrossSellWrapper = inject('currentHotel')(observer(({
  inputs
}) => React.createElement(PropertyActivitiesCrossSell, {
  sectionRef: "Offers",
  inputs: inputs,
  skipSsr: true
})));
PropertyActivitiesCrossSellWrapper.displayName = 'PropertyActivitiesCrossSellWrapper';
export const SharedUiPropertyActivitiesCrossSellWrapper = withSharedUiPropertyOffers(PropertyActivitiesCrossSellWrapper);