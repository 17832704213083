import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertySpecialFeatures } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const SpecialFeaturesWrapper = ({
  inputs,
  columns
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement("div", null, React.createElement(PropertySpecialFeatures, {
    inputs: inputs,
    columns: columns,
    skipSsr: cSRPropertyDetailsQuery
  })));
};
export const SharedUiSpecialFeaturesWrapper = withSharedUiPropertyDetails(SpecialFeaturesWrapper);
SharedUiSpecialFeaturesWrapper.displayName = 'SharedUiSpecialFeaturesWrapper';