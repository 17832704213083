import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { VipMessagingCard } from '@shared-ui/lodging-property-offers';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const PropertyHighlightWrapper = React.memo(({
  inputs
}) => React.createElement(ErrorBoundary, {
  label: "hotel.property.highlights"
}, React.createElement(SimpleLazyLoadWrapper, {
  offset: 400
}, React.createElement(EGDSSpacing, {
  margin: {
    blockend: 'three',
    small: {
      inline: 'three'
    },
    medium: {
      inline: 'unset'
    }
  }
}, React.createElement("div", null, React.createElement(VipMessagingCard, {
  inputs: inputs,
  skipSsr: true
}))))));
PropertyHighlightWrapper.displayName = 'PropertyHighlightWrapper';
export const SharedUiPropertyHighlightWrapper = withSharedUiPropertyOffers(PropertyHighlightWrapper);