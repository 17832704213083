import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { QueryRoute, updateSearch } from 'bernie-core';
import { Layer } from 'bernie-view';
import { SharedUiMap } from 'src/common/components/infosite/shared-ui/details/map-wrapper';
import { SharedUiHighlightedBenefitsWrapper } from 'src/common/components/infosite/shared-ui/offers/highlighted-benefits-wrapper';
import { PropertySummary } from '@shared-ui/lodging-property-details';
import { useQueryParamStoredPdpPropertyName } from '../../property-details/use-stored-pdp-image-and-property-name';
import { SharedUiUnitsCarouselWrapper } from '../offers/units-carousel-wrapper';
import { SharedUiPropertyReviewsDialog } from '../reviews/shared-ui-property-reviews-dialog';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const SummaryWrapper = inject('analyticsSearch')(observer(({
  analyticsSearch,
  inputs
}) => {
  const history = useHistory();
  const location = useLocation();
  const triggerReviewRef = React.useRef(null);
  const propertyName = useQueryParamStoredPdpPropertyName();
  const propertySummaryPlaceHolderData = React.useMemo(() => propertyName ? {
    summary: {
      name: propertyName
    }
  } : {}, [propertyName]);
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  const onClickMap = React.useCallback(() => updateSearch({
    history,
    location,
    newParams: {
      pwaDialog: 'map'
    }
  }), [history, location]);
  const onClickReviews = React.useCallback(() => updateSearch({
    history,
    location,
    newParams: {
      pwaDialog: 'reviews'
    }
  }), [history, location]);
  const onClose = React.useCallback(() => history.goBack(), [history]);
  return React.createElement(React.Fragment, null, React.createElement(PropertySummary, {
    onClickReviews: onClickReviews,
    onClickMap: onClickMap,
    inputs: inputs,
    triggerRef: triggerReviewRef,
    placeholderData: propertySummaryPlaceHolderData,
    skipSsr: cSRPropertyDetailsQuery,
    showHighlights: true,
    searchId: analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.searchId,
    pageId: "HOT.HIS.Dated"
  }, React.createElement(PropertySummary.CarouselComponent, null, React.createElement(SharedUiUnitsCarouselWrapper, null)), React.createElement(PropertySummary.HighlightedBenefitsComponent, null, React.createElement(SharedUiHighlightedBenefitsWrapper, null)), React.createElement(PropertySummary.PropertyDetailsMapComponent, null, React.createElement(SharedUiMap, {
    onClose: onClose
  }))), React.createElement(SharedUiPropertyReviewsDialog, {
    dialogType: "reviews",
    triggerRef: triggerReviewRef
  }));
}));
export const MapDialogWrapper = () => {
  const history = useHistory();
  const onCloseDialog = React.useCallback(() => history.goBack(), [history]);
  return React.createElement(QueryRoute, {
    query: {
      pwaDialog: 'map'
    }
  }, React.createElement(Layer, {
    id: "infosite-map",
    onDismiss: onCloseDialog
  }, React.createElement(SharedUiMap, {
    onClose: onCloseDialog
  })));
};
MapDialogWrapper.displayName = 'MapDialogWrapper';
const MemoizedSummaryWrapper = React.memo(({
  inputs
}) => React.createElement(SummaryWrapper, {
  inputs: inputs
}));
export const SharedUiSummaryWrapper = withSharedUiPropertyDetails(MemoizedSummaryWrapper);
SharedUiSummaryWrapper.displayName = 'SharedUiSummaryWrapper';