import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { useStores } from 'src/common/stores/use-stores';
import { RefTarget } from '@shared-ui/ref-context';
import { IdentifierType, RandomAccessOne } from '@shared-ui/retail-product-random-access-one';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { SharedUiPropertyReviewsDialog } from './shared-ui-property-reviews-dialog';
export const sharedUIReviewsDialog = 'sui-see-all-reviews-dialog';
export const SharedUiPropertyReviewsWrapper = () => {
  var _a;
  const {
    context,
    currentHotel
  } = useStores();
  const propertyId = (_a = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) === null || _a === void 0 ? void 0 : _a.toString();
  const searchCriteria = context && currentHotel && adaptSearchCriteria(context, currentHotel.searchCriteria);
  if (!propertyId) {
    return null;
  }
  return React.createElement(ErrorBoundary, {
    label: "hotel.information.reviews"
  }, React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(RefTarget, {
    name: "Reviews"
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(RandomAccessOne, {
    inputs: {
      featureId: 'vision_reviews',
      productIdentifier: {
        id: propertyId,
        type: IdentifierType.PROPERTY_ID,
        travelSearchCriteria: {
          property: searchCriteria
        }
      }
    },
    batchKey: "default-product-details-experience-batch-key"
  }), React.createElement(SharedUiPropertyReviewsDialog, {
    dialogType: sharedUIReviewsDialog
  })))));
};
SharedUiPropertyReviewsWrapper.displayName = 'SharedUiPropertyReviewsWrapper';