import * as React from 'react';
import { inject } from 'mobx-react';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { useLocalization } from '@shared-ui/localization-context';
import { PropertyShortlist } from '@shared-ui/lodging-property-details';
import { ShortlistToggleProvider } from '@shared-ui/trip-planning-travelpulse';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const ShortlistWrapper = inject('uiPage')(({
  inputs,
  uiPage
}) => {
  const {
    formatText
  } = useLocalization();
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  /**
   * @vdorsonnens [30/04/21]
   * We need to override the global shortlist provider (src/common/components/common/shortlist/shortlist-provider.tsx)
   * temporarily until I get the full toast message to be returned from the spinner. Currently the shared-ui component only
   * dispatches the propertyName.
   */
  const handler = ({
    message
  }) => {
    const notification = formatText('hotels.shortlist.saveToList.notification', message);
    uiPage === null || uiPage === void 0 ? void 0 : uiPage.showNotification(notification);
    liveAnnounce(notification, Level.POLITE);
  };
  return React.createElement(ShortlistToggleProvider, {
    dispatchHandler: handler
  }, React.createElement(PropertyShortlist, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  }));
});
export const SharedUiShortlistWrapper = withSharedUiPropertyDetails(ShortlistWrapper);
SharedUiShortlistWrapper.displayName = 'SharedUiShortlistWrapper';