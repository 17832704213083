import * as React from 'react';
import { CustomerNotificationLocation } from '@shared-ui/customer-notifications';
import { CustomerNotifications } from 'components/common/customer-notifications';
import { MitHeader } from 'components/multi-item/multi-item-header';
export const PropertySummaryHeader = ({
  classes,
  regionId,
  startDate,
  endDate
}) => React.createElement("div", {
  className: classes
}, React.createElement(MitHeader, null), React.createElement(CustomerNotifications, {
  location: CustomerNotificationLocation.AFTER_HEADER,
  regionId: regionId,
  startDate: startDate,
  endDate: endDate
}), React.createElement(CustomerNotifications, {
  location: CustomerNotificationLocation.IN_PAGE,
  regionId: regionId,
  startDate: startDate,
  endDate: endDate,
  spacingProps: {
    margin: {
      blockstart: 'three'
    }
  }
}));
PropertySummaryHeader.displayName = 'PropertySummaryHeader';