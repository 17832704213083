import * as React from 'react';
import { inject } from 'mobx-react';
import { EGDSSheet, EGDSSheetContent, EGDSSheetTransition } from '@egds/react-core/sheet';
import { EGDSToolbar } from '@egds/react-core/toolbar';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { useDialog } from '@shared-ui/dialog-context';
import { useLocalization } from '@shared-ui/localization-context';
import { PropertyFilteredReviews } from '@shared-ui/lodging-reviews';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
export const SharedUiPropertyReviewsDialog = ({
  dialogType,
  triggerRef
}) => React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(PropertyReviewsDialog, {
  dialogType: dialogType,
  triggerRef: triggerRef,
  sheetType: "full"
})), React.createElement(ViewMedium, null, React.createElement(PropertyReviewsDialog, {
  dialogType: dialogType,
  sheetHeight: 1200,
  triggerRef: triggerRef,
  sheetType: "centered"
})));
SharedUiPropertyReviewsDialog.displayName = 'SharedUiPropertyReviewsDialog';
const PropertyReviewsDialog = inject('analytics', 'currentHotel', 'context')(({
  analytics,
  currentHotel,
  context,
  triggerRef = null,
  dialogType,
  sheetType,
  sheetHeight
}) => {
  var _a, _b, _c;
  const [isDialogOpen, dialogActions, DialogComponent] = useDialog(dialogType, 'pwaDialog');
  const onDialogClose = () => {
    dialogActions.closeDialog();
  };
  /* istanbul ignore next */
  const propertyId = (_a = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) === null || _a === void 0 ? void 0 : _a.toString();
  const searchCriteria = context && currentHotel && adaptSearchCriteria(context, currentHotel.searchCriteria);
  const {
    formatText
  } = useLocalization();
  if (!propertyId || !searchCriteria) {
    return null;
  }
  /* istanbul ignore next */
  const isDated = Boolean((_c = (_b = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria) === null || _b === void 0 ? void 0 : _b.startDate) === null || _c === void 0 ? void 0 : _c.length);
  return React.createElement(EGDSSheetTransition, {
    isVisible: isDialogOpen
  }, React.createElement(DialogComponent, null, React.createElement(EGDSSheet, {
    type: sheetType,
    height: sheetHeight,
    isVisible: isDialogOpen,
    returnFocusOnClose: true,
    triggerRef: triggerRef
  }, React.createElement(EGDSToolbar, {
    toolbarTitle: formatText('hotels.infosite.reviews.title'),
    navigationContent: {
      onClick: onDialogClose,
      navIconLabel: formatText('hotels.infosite.reviews.cancelButton.a11yLabel'),
      onClickAnalytics: analytics === null || analytics === void 0 ? void 0 : analytics.setupTracker('ReviewsDialog', 'close reviews')
    }
  }), React.createElement(EGDSSheetContent, {
    fullSheetContentMaxWidth: "extra-large"
  }, React.createElement(PropertyFilteredReviews, {
    inputs: {
      propertyId,
      searchCriteria,
      includeRecentReviews: isDated
    }
  })))));
});
PropertyReviewsDialog.displayName = 'PropertyReviewsDialog';