import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { DamageAndIncidentalsCard } from '@shared-ui/lodging-property-offers';
import { SimpleLazyLoadWrapper } from 'components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const DamageAndIncidentalsWrapper = React.memo(({
  inputs
}) => React.createElement(ErrorBoundary, {
  label: "hotel.property.damages"
}, React.createElement("div", {
  "data-stid": "shared-ui-damage-and-incidentals"
}, React.createElement(SimpleLazyLoadWrapper, {
  offset: 400
}, React.createElement(EGDSSpacing, {
  margin: {
    blockend: 'three'
  }
}, React.createElement(DamageAndIncidentalsCard, {
  inputs: inputs,
  skipSsr: true
}))))));
DamageAndIncidentalsWrapper.displayName = 'SharedUIDamageAndIncidentalsWrapper';
export const SharedUIDamageAndIncidentalsWrapper = withSharedUiPropertyOffers(DamageAndIncidentalsWrapper);