var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { ClickTracker } from 'bernie-plugin-analytics';
import { EGDSIcon } from '@egds/react-core/icons';
import { EGDSLink } from '@egds/react-core/link';
import { EGDSText } from '@egds/react-core/text';
import { withLocalization } from '@shared-ui/localization-context';
import { DateUtils } from 'utils/date-utils';
const MAXIMUM_DATE_SPAN = 28;
const NUM_OF_DAYS_BOOKABLE_IN_ADVANCE = 500;
const LinkTracked = ClickTracker(EGDSLink);
let StepperButton = class StepperButton extends React.Component {
  constructor(props) {
    super(props);
    this.stepDates = this.stepDates.bind(this);
    this.state = {
      mounted: false
    };
    this.dateUtils = new DateUtils(props.l10n);
  }
  stepDates() {
    if (this.props.clickHandler && typeof this.props.clickHandler === 'function') {
      this.props.clickHandler(this.determineStepDate(), true);
    }
  }
  determineIfDisabled() {
    const determineStepDateResult = this.props.startDate && this.props.endDate && this.determineStepDate();
    return !this.state.mounted || !determineStepDateResult;
  }
  determineStepDate() {
    const {
      dateUtils
    } = this;
    const {
      endDate,
      startDate,
      stepperType
    } = this.props;
    const currentCheckInDate = startDate;
    const currentCheckOutDate = endDate;
    const target = [];
    const stepDateEvent = {
      target
    };
    let stepCheckInDate;
    let stepCheckOutDate;
    switch (stepperType) {
      case 'CHECK_IN_MINUS':
        stepCheckInDate = dateUtils.getISODayBefore(currentCheckInDate);
        if (DateUtils.isBefore(stepCheckInDate, dateUtils.getToday())) {
          stepCheckInDate = undefined;
        } else if (dateUtils.getDaysBetween(stepCheckInDate, currentCheckOutDate) >= MAXIMUM_DATE_SPAN) {
          stepCheckInDate = undefined;
        }
        break;
      case 'CHECK_IN_PLUS':
        stepCheckInDate = dateUtils.getISODayAfter(currentCheckInDate);
        if (dateUtils.getDaysBetween(dateUtils.getToday(), stepCheckInDate) >= NUM_OF_DAYS_BOOKABLE_IN_ADVANCE) {
          stepCheckInDate = undefined;
        } else if (dateUtils.isSame(stepCheckInDate, currentCheckOutDate)) {
          stepCheckOutDate = dateUtils.getISODayAfter(currentCheckOutDate);
        } else if (dateUtils.getDaysBetween(currentCheckInDate, currentCheckOutDate) >= MAXIMUM_DATE_SPAN) {
          stepCheckInDate = dateUtils.getNDaysFrom(currentCheckOutDate, -(MAXIMUM_DATE_SPAN - 1));
        }
        break;
      case 'CHECK_OUT_MINUS':
        stepCheckOutDate = dateUtils.getISODayBefore(currentCheckOutDate);
        if (DateUtils.isBefore(stepCheckOutDate, dateUtils.getISODayAfter(dateUtils.getToday()))) {
          stepCheckOutDate = undefined;
        } else if (dateUtils.isSame(stepCheckOutDate, currentCheckInDate)) {
          stepCheckInDate = dateUtils.getISODayBefore(currentCheckInDate);
        } else if (dateUtils.getDaysBetween(currentCheckInDate, currentCheckOutDate) >= MAXIMUM_DATE_SPAN) {
          stepCheckOutDate = dateUtils.getNDaysFrom(currentCheckInDate, MAXIMUM_DATE_SPAN - 1);
        }
        break;
      case 'CHECK_OUT_PLUS':
        stepCheckOutDate = dateUtils.getISODayAfter(currentCheckOutDate);
        if (dateUtils.getDaysBetween(dateUtils.getToday(), stepCheckOutDate) > NUM_OF_DAYS_BOOKABLE_IN_ADVANCE) {
          stepCheckOutDate = undefined;
        } else if (dateUtils.getDaysBetween(currentCheckInDate, stepCheckOutDate) >= MAXIMUM_DATE_SPAN) {
          stepCheckOutDate = undefined;
        }
        break;
      default:
        stepCheckInDate = undefined;
        stepCheckOutDate = undefined;
        break;
    }
    if (!stepCheckInDate && !stepCheckOutDate) {
      return false;
    }
    if (stepCheckInDate) {
      stepDateEvent.target.push({
        name: 'startDate',
        value: stepCheckInDate
      });
    }
    if (stepCheckOutDate) {
      stepDateEvent.target.push({
        name: 'endDate',
        value: stepCheckOutDate
      });
    }
    return stepDateEvent;
  }
  componentDidMount() {
    this.setState({
      mounted: true
    });
  }
  render() {
    const {
      l10n,
      linkName,
      rfrr,
      stepperType
    } = this.props;
    let iconName = '';
    let identifier = 'date-stepper-button';
    let ariaLabelKey = '';
    let align = 'left';
    switch (stepperType) {
      case 'CHECK_IN_MINUS':
        identifier += '-check-in-previous';
        iconName = 'chevron_left';
        ariaLabelKey = 'hotels.infosite.availabilityWizard.changeDate.checkIn.decrease.a11yLabel';
        break;
      case 'CHECK_OUT_MINUS':
        identifier += '-check-out-previous';
        iconName = 'chevron_left';
        ariaLabelKey = 'hotels.infosite.availabilityWizard.changeDate.checkOut.decrease.a11yLabel';
        break;
      case 'CHECK_IN_PLUS':
        align = 'right';
        identifier += '-check-in-next';
        iconName = 'chevron_right';
        ariaLabelKey = 'hotels.infosite.availabilityWizard.changeDate.checkIn.increase.a11yLabel';
        break;
      case 'CHECK_OUT_PLUS':
        align = 'right';
        identifier += '-check-out-next';
        iconName = 'chevron_right';
        ariaLabelKey = 'hotels.infosite.availabilityWizard.changeDate.checkOut.increase.a11yLabel';
        break;
      default:
        break;
    }
    return React.createElement(LinkTracked, {
      "data-stid": identifier,
      align: align,
      linkName: linkName,
      disabled: this.determineIfDisabled(),
      onClick: this.stepDates,
      rfrr: rfrr,
      "aria-label": l10n === null || l10n === void 0 ? void 0 : l10n.formatText(ariaLabelKey)
    }, React.createElement("button", null, React.createElement(EGDSText, {
      align: "center"
    }, React.createElement(EGDSIcon, {
      name: iconName
    }))));
  }
};
StepperButton.defaultProps = {
  iconName: '',
  l10n: null
};
StepperButton = __decorate([withLocalization], StepperButton);
export { StepperButton };