import * as React from 'react';
import { inject } from 'mobx-react';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core/cards';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSLayoutPosition } from '@egds/react-core/layout-position';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSSticky } from '@egds/react-core/sticky';
import { useRefProvider } from 'src/common/stores/contexts/ref-provider';
import { useExperiment } from '@shared-ui/experiment-context';
import { RefTarget, useRefContext } from '@shared-ui/ref-context';
import { IdentifierType, NavigationBar } from '@shared-ui/retail-product-navigation-bar';
import { ViewLarge, Viewport } from '@shared-ui/viewport-context';
// Components
import ViewportListener from 'components/common/lazy/viewport-listener';
import { StickyBookBar } from 'components/infosite/template-components/sticky-book-bar';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { SharedUiSaveTripItem } from './shared-ui-save-trip-item';
const NavigationBarWrapper = inject('currentHotel', 'multiItem')(({
  inputs,
  showReserveBtn,
  showPriceOnLoad = false,
  showNightlyPrice,
  showReserveBtnOnLoad
}) => {
  const [priceboxInView, setPriceboxInView] = React.useState(!showPriceOnLoad);
  const [reserveBtnInView, setReserveBtnInView] = React.useState(showReserveBtnOnLoad);
  const [singleOfferRef] = useRefProvider('singleOfferPrice');
  const [saveButtonInView, setSaveButtonInView] = React.useState(false);
  const {
    getTargets
  } = useRefContext();
  const {
    Offers: offersRef
  } = getTargets(['Offers']);
  const {
    SummaryBackLink: summaryBackLinkRef
  } = getTargets(['SummaryBackLink']);
  const singleOfferRefCurrent = singleOfferRef === null || singleOfferRef === void 0 ? void 0 : singleOfferRef.current;
  const offersRefCurrent = offersRef === null || offersRef === void 0 ? void 0 : offersRef.current;
  const summaryBackLinkRefCurrent = summaryBackLinkRef === null || summaryBackLinkRef === void 0 ? void 0 : summaryBackLinkRef.current;
  const viewportListener = new ViewportListener();
  const layoutPosition = {
    right: 'zero',
    top: 'zero',
    bottom: 'zero'
  };
  const {
    exposure: additionalEntrypointPdpLodgingLxExperimentExposure,
    logExperiment: logAdditionalEntrypointPdpLodgingLxExperiment
  } = useExperiment('Additional_entrypoint_PDP_lodgingLX');
  React.useEffect(() => {
    if (singleOfferRefCurrent) {
      viewportListener.subscribe('window', singleOfferRefCurrent, {
        didEnter: () => setPriceboxInView(true),
        didExit: () => setPriceboxInView(false)
      });
    }
  }, [singleOfferRefCurrent]);
  React.useEffect(() => {
    if (offersRefCurrent) {
      new IntersectionObserver(entries => entries.forEach(entry => setReserveBtnInView(!entry.isIntersecting)), {
        rootMargin: '0% 0% -50% 0%'
      }).observe(offersRefCurrent);
    }
  }, [offersRefCurrent]);
  React.useEffect(() => {
    if (summaryBackLinkRefCurrent) {
      new IntersectionObserver(entries => entries.forEach(entry => setSaveButtonInView(!entry.isIntersecting)), {
        threshold: 0.3
      }).observe(summaryBackLinkRefCurrent);
    } else {
      setSaveButtonInView(true);
    }
  }, [summaryBackLinkRefCurrent]);
  logAdditionalEntrypointPdpLodgingLxExperiment(additionalEntrypointPdpLodgingLxExperimentExposure);
  const showSaveButton = saveButtonInView && additionalEntrypointPdpLodgingLxExperimentExposure.bucket === 1;
  const productInputs = Object.assign(Object.assign({}, inputs), {
    productIdentifier: {
      id: inputs.propertyId,
      type: IdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: inputs.searchCriteria
      }
    }
  });
  return React.createElement(EGDSSticky, {
    zIndex: 400
  }, React.createElement(RefTarget, {
    name: "navigation"
  }, React.createElement(React.Fragment, null, React.createElement(EGDSCard, {
    className: "custom-css-flat-border-top custom-css-flat-border-bottom"
  }, React.createElement(EGDSCardContentSection, {
    border: "bottom",
    padded: false,
    "data-stid": "uitk-card-content-section-navbar"
  }, React.createElement(NavigationBar, {
    inputs: productInputs
  }))), (showReserveBtn && !priceboxInView || !showNightlyPrice && reserveBtnInView || showSaveButton) && React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(EGDSLayoutPosition, {
    type: "absolute",
    position: layoutPosition
  }, React.createElement(EGDSSpacing, null, React.createElement(EGDSLayoutFlex, {
    blockSize: 'full_size',
    justifyContent: "end",
    alignItems: "center"
  }, React.createElement(EGDSSpacing, {
    padding: {
      inlineend: 'three'
    }
  }, React.createElement(EGDSLayoutFlexItem, {
    grow: 0
  }, React.createElement(EGDSLayoutFlex, {
    alignItems: "center",
    alignContent: "center",
    blockSize: 'full_size'
  }, showSaveButton && React.createElement("div", {
    "data-stid": "sharedui-trip-save-item-button"
  }, React.createElement(EGDSSpacing, {
    margin: {
      inlineend: 'two'
    }
  }, React.createElement("div", null, React.createElement(SharedUiSaveTripItem, {
    inputs: inputs
  })))), (showReserveBtn && !priceboxInView || !showNightlyPrice && reserveBtnInView) && React.createElement("div", {
    "data-stid": "navbar-quick-reserve-button"
  }, React.createElement(StickyBookBar, null)))))))))))));
});
export const SharedUiNavigationBarWrapper = withSharedUiPropertyDetails(NavigationBarWrapper);
SharedUiNavigationBarWrapper.displayName = 'SharedUiNavigationBarWrapper';