var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { getShoppingContextInput } from 'src/common/stores/adapters/bex-api/search/shopping-context-adapter';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { getDateRangeFromSearchCriteria } from 'stores/adapters';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
/**
 * HOC that'll check if the hotelId is properly set, and return a memoized component that'll
 * only re-trigger its graphql query if the hotelId changes. It will inject the inputs props
 * so your wrapper can pass it down to its shared-ui component, all other props are simply drilled
 * down.
 * @param SharedUiWrapper {React.FC<SharedUiWrapperProps>}
 */
export const withSharedUiPropertyDetails = SharedUiWrapper => {
  const SharedUiPropertyDetailsComponent = inject('currentHotel', 'multiItem', 'context', 'experiment', 'analyticsSearch')(observer(_a => {
    var _b, _c;
    var {
        currentHotel,
        multiItem,
        context,
        analyticsSearch
      } = _a,
      props = __rest(_a, ["currentHotel", "multiItem", "context", "analyticsSearch"]);
    const {
      hotelId,
      searchCriteria
    } = currentHotel || {};
    const location = useLocation();
    const multiItemContext = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext;
    const shoppingContext = getShoppingContextInput(multiItemContext);
    const dateRange = searchCriteria && getDateRangeFromSearchCriteria(searchCriteria);
    // identifier will be set only for vrbo brand
    const identifier = isVrbo(context) ? location.pathname : undefined;
    // This is safe, because you're not actually rendering the component here
    // So we're golden even if the hotelId is null.
    const Component = React.useMemo(() => withSharedUiPropertyDetailsInputs(SharedUiWrapper), [hotelId, (_b = shoppingContext.multiItem) === null || _b === void 0 ? void 0 : _b.id, (_c = shoppingContext.multiItem) === null || _c === void 0 ? void 0 : _c.packageType]);
    if (!hotelId || !searchCriteria) {
      return null;
    }
    const propertySearchCriteria = context && adaptSearchCriteria(context, searchCriteria, undefined, undefined, analyticsSearch);
    return React.createElement(Component, Object.assign({
      hotelId: String(hotelId),
      dateRange: dateRange ? dateRange : undefined,
      shoppingContext: shoppingContext,
      searchCriteria: propertySearchCriteria,
      identifier: identifier
    }, props));
  }));
  SharedUiPropertyDetailsComponent.displayName = 'SharedUiPropertyDetailsComponent';
  return SharedUiPropertyDetailsComponent;
};
/**
 * Function that builds the graphql input for property-details shared-ui
 * components. Very useful to make sure that all shared-ui components get
 * the same inputs, so queries aren't duplicated.
 * Not for external use, only use within {withSharedUiPropertyDetails}
 * or at your own risk.
 */
const withSharedUiPropertyDetailsInputs = SharedUiWrapper => {
  const SharedUiPropertyDetailsWithInputs = _a => {
    var _b, _c;
    var {
        hotelId,
        dateRange,
        shoppingContext,
        searchCriteria,
        identifier
      } = _a,
      props = __rest(_a, ["hotelId", "dateRange", "shoppingContext", "searchCriteria", "identifier"]);
    const inputs = React.useMemo(() => ({
      propertyId: hotelId || '',
      dateRange: dateRange,
      shoppingContext: shoppingContext,
      isDateless: false,
      searchCriteria: searchCriteria,
      identifier: identifier
    }), [hotelId, (_b = shoppingContext === null || shoppingContext === void 0 ? void 0 : shoppingContext.multiItem) === null || _b === void 0 ? void 0 : _b.id, (_c = shoppingContext === null || shoppingContext === void 0 ? void 0 : shoppingContext.multiItem) === null || _c === void 0 ? void 0 : _c.packageType]);
    return React.createElement(SharedUiWrapper, Object.assign({
      inputs: inputs
    }, props));
  };
  return SharedUiPropertyDetailsWithInputs;
};