var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
/* eslint-disable camelcase */
import * as React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { ApolloLink, useApolloClient } from '@apollo/client';
import { inject } from 'mobx-react';
import { mergeQueryString, updateSearch } from 'bernie-core';
import { parse } from 'query-string';
import { CLICKSTREAM_CONFIG } from 'src/common/constants/analytics';
import { getHotelInfositePageData } from 'src/common/utils/controller-utils/page-data-util';
import { structureClickstreamResetData } from 'src/common/utils/tracking-utils';
import { ITrackType, useClickstreamAnalytics } from '@shared-ui/clickstream-analytics-context';
import { referrerMapper, uisMicroMessagesAggregator } from '@shared-ui/clickstream-analytics-context/dist/commonjs/uis-prime/util';
import { Qualtrics } from '@shared-ui/customer-qualtrics';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { PropertyDetailsAdaptExProvider } from '@shared-ui/lodging-property-details';
import { PackageOperationsProvider } from '@shared-ui/multi-item-operations-providers';
import { RefProviderRoot } from '@shared-ui/ref-context';
import { ContactHostContextProvider } from '@shared-ui/retail-lodging-contact-host';
import { GoogleMapsSdkVersionProvider } from '@shared-ui/retail-map-google-maps-sdk-version-provider';
import { ProductGalleryAdaptExProvider } from '@shared-ui/retail-product-gallery';
import { SignalEventsContextProvider } from '@shared-ui/signal-events-context';
import { PubSubEventManager } from '@shared-ui/signal-events-context_pub-sub-observer';
import { RefProvider } from 'stores/contexts/ref-provider';
import { ShortlistProvider } from 'components/common/shortlist/shortlist-provider';
import { ErrorLink } from 'components/infosite/error-link';
import EventWrapper from 'components/infosite/property-details/event-wrapper';
import { LegalDisclaimer } from 'components/infosite/template-components/legal-disclaimer';
import { TemplateRenderer } from 'components/infosite/template-components/template-renderer';
import { useInfositePageTitleCacheRead } from 'components/infosite/use-infosite-page-title-cache-read';
import { useMultiItemCacheRead } from 'components/infosite/use-multi-item-context-cache-read';
import { MainRegion } from 'components/layout/pages/main-region';
import { MasterPage } from 'components/layout/pages/master-page';
import { MesoFooter } from 'components/meso/meso-footer';
import { LODGING_PRODUCT_LINE, setContextHeaders } from 'utils/apollo-link-util';
import { MapsSdkVersion } from 'constants/maps';
import { InfositePageName } from 'constants/pages';
export const setAnalyticsPayload = (response, operation, currentHotel, analytics) => {
  var _a;
  const extensionsAnalyticsResponseNode = (_a = response === null || response === void 0 ? void 0 : response.extensions) === null || _a === void 0 ? void 0 : _a.analytics;
  if (Array.isArray(extensionsAnalyticsResponseNode) && extensionsAnalyticsResponseNode.length > 0 && extensionsAnalyticsResponseNode[0]) {
    currentHotel.addPayload(operation.operationName, extensionsAnalyticsResponseNode[0], analytics);
  }
  return response;
};
const Infosite = inject('currentHotel', 'analytics', 'multiItem', 'page', 'context')(_a => {
  var {
      currentHotel,
      analytics,
      multiItem,
      page,
      context
    } = _a,
    props = __rest(_a, ["currentHotel", "analytics", "multiItem", "page", "context"]);
  const client = useApolloClient();
  const location = useLocation();
  const {
    pageId,
    pageName,
    clickstreamPageName,
    clickstreamPageNameDetailed
  } = getHotelInfositePageData(parse(location.search));
  if (page) {
    page.pageId = pageId;
    page.pageName = pageName;
  }
  useInfositePageTitleCacheRead(currentHotel, page);
  useMultiItemCacheRead(currentHotel, multiItem);
  const {
    track,
    reset
  } = useClickstreamAnalytics();
  const {
    bucket: isRenderingTrackingVariant
  } = useExperiment('Rendering_Tracking', true).exposure;
  // reseting the data in egClickstream as the fetchPageData (https://github.expedia.biz/Brand-Expedia/shopping-pwa/blob/master/src/common/controllers/lodging/infosite-controller.ts#L59) is not called on client side navigation currently which hinders the cickstream data update. Unable to write the function call within a useEffect to avoid unnecessary re-render calls to reset function, as couple of clickstream events skips these changes.
  reset(Object.assign(Object.assign({}, structureClickstreamResetData(clickstreamPageName, clickstreamPageNameDetailed)), {
    config: CLICKSTREAM_CONFIG
  }));
  React.useEffect(() => {
    if (isRenderingTrackingVariant) {
      track(ITrackType.EVENT, {
        data: uisMicroMessagesAggregator(referrerMapper('HOT.HIS.Rendered', 'PDP Rendering Tracking', 'impression'))
      });
    }
  }, []);
  const analyticsLink = new ApolloLink((operation, forward) => setContextHeaders(operation, forward, LODGING_PRODUCT_LINE, context.site.brand).map(response => setAnalyticsPayload(response, operation, currentHotel, analytics)));
  if (typeof window !== 'undefined' && currentHotel && !currentHotel.apolloLinkSetOnBrowser) {
    client.setLink(ApolloLink.from([analyticsLink, client.link]));
    currentHotel.apolloLinkSetOnBrowser = true;
  } else if (typeof window === 'undefined' && currentHotel && !currentHotel.apolloLinkSetOnServer) {
    client.setLink(ApolloLink.from([analyticsLink, client.link]));
    currentHotel.apolloLinkSetOnServer = true;
  }
  return React.createElement(React.Fragment, null, React.createElement(Experiment, {
    name: "AA_test_to_monitor_site_health"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  })), React.createElement(Experiment, {
    name: "Lodging_Tag_Commander_Delay"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }), React.createElement(ExperimentVariant, {
    bucket: 2
  }), React.createElement(ExperimentVariant, {
    bucket: 3
  })), React.createElement(InfositeClassComponent, Object.assign({}, props)));
});
export default Infosite;
let InfositeClassComponent = class InfositeClassComponent extends React.Component {
  constructor(props) {
    var _a, _b, _c, _d;
    super(props);
    if (this.props.context && this.props.experiment && this.props.multiItem && this.props.multiItem.isMixMatchEnabled && typeof this.props.multiItem.getOrCreateCart === 'function' && !this.props.multiItem.isPackageShoppingPath()) {
      this.props.multiItem.getOrCreateCart(this.props.context);
    }
    (_a = this.props.advertising) === null || _a === void 0 ? void 0 : _a.includeAd();
    if ((_b = this.props.multiItem) === null || _b === void 0 ? void 0 : _b.multiItemContext) {
      (_c = this.props.advertising) === null || _c === void 0 ? void 0 : _c.trigger(InfositePageName[this.props.multiItem.multiItemContext.packageType]);
    } else {
      (_d = this.props.advertising) === null || _d === void 0 ? void 0 : _d.trigger(InfositePageName.SA);
    }
  }
  componentDidMount() {
    const {
      location,
      history
    } = this.props;
    const {
      propertyUnitRoomInfoDialog
    } = parse(location.search);
    if (propertyUnitRoomInfoDialog) {
      const query = mergeQueryString(location.search, {}, key => !key.startsWith('propertyUnitRoomInfoDialog'));
      const queryCombiner = (prevQuery, newQuery) => newQuery;
      updateSearch({
        history,
        location,
        newParams: query,
        replace: true,
        queryCombiner
      });
    }
    window.scrollTo(0, 0);
  }
  componentDidUpdate(prevProps) {
    const {
      uiPage,
      location,
      history
    } = this.props;
    const {
      scrollPosition
    } = parse(location.search);
    if (scrollPosition) {
      uiPage === null || uiPage === void 0 ? void 0 : uiPage.setFocusItem(scrollPosition);
      const query = mergeQueryString(location.search, {}, key => !key.startsWith('scrollPosition'));
      const queryCombiner = (prevQuery, newQuery) => newQuery;
      updateSearch({
        history,
        location,
        newParams: query,
        replace: true,
        queryCombiner
      });
    } else if (location && prevProps.location && location.pathname !== prevProps.location.pathname && history.action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }
  componentWillUnmount() {
    var _a;
    (_a = this.props.currentHotel) === null || _a === void 0 ? void 0 : _a.setIsNavigationFromSRP(false);
  }
  render() {
    const {
      currentHotel,
      route
    } = this.props;
    const {
      isFailure
    } = currentHotel || {};
    const masterProps = {
      'data-stid': 'infosite-page-container',
      route
    };
    return React.createElement(RefProvider, null, React.createElement(RefProviderRoot, null, React.createElement(GoogleMapsSdkVersionProvider, {
      version: MapsSdkVersion.google
    }, React.createElement(EventWrapper, null, React.createElement(ShortlistProvider, null, React.createElement(PropertyDetailsAdaptExProvider, null, React.createElement(ProductGalleryAdaptExProvider, null, React.createElement(PackageOperationsProvider, {
      autoRenderFailureDialog: true
    }, React.createElement(SignalEventsContextProvider, {
      eventManager: PubSubEventManager
    }, React.createElement(MasterPage, Object.assign({}, masterProps, {
      templateContent: React.createElement(MainRegion, null, isFailure && React.createElement(ErrorLink, null), React.createElement(ContactHostContextProvider, null, React.createElement("div", {
        className: "constrain-width"
      }, React.createElement(TemplateRenderer, {
        pageLayout: this.props.pageLayout
      }))), React.createElement(MesoFooter, null), React.createElement(LegalDisclaimer, null))
    }))))))), React.createElement(Experiment, {
      name: "EGTNL_Lodging_PWA_Qualtrics"
    }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
      bucket: 1
    }, React.createElement(Qualtrics, {
      skipSsr: true
    })))))));
  }
};
InfositeClassComponent = __decorate([withRouter, inject('advertising', 'analytics', 'context', 'currentHotel', 'multiItem', 'pageLayout', 'uiPage', 'experiment')], InfositeClassComponent);