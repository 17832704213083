import { usePropertyOffersContext } from '@shared-ui/lodging-property-offers';
import { useSponsoredPropertiesContext } from '@shared-ui/retail-lodging-sponsored-properties-carousel';
import { useRecommendationModuleContext } from '@shared-ui/retail-recommendation-module';
import { useSearchCriteriaContext } from '../stores/contexts';
import { usePlatformContext } from '../stores/contexts/platformContext';
export const usePropertyCarouselContexts = () => {
  const context = usePlatformContext();
  const searchCriteria = useSearchCriteriaContext();
  const {
    isProperties
  } = useSponsoredPropertiesContext();
  const {
    recommendationModulesStatus
  } = useRecommendationModuleContext();
  const {
    availableRooms,
    isLoading
  } = usePropertyOffersContext();
  return {
    context,
    searchCriteria,
    isProperties,
    isLoading,
    availableRooms,
    recommendationModulesStatus
  };
};