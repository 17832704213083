import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ExperienceTemplateRenderer, ExperienceTemplateRendererContextProvider } from 'experience-template-renderer-react';
import { CarouselProvider } from 'src/common/stores/contexts/carousel-context-provider';
import { PropertyOffersContextProvider } from '@shared-ui/lodging-property-offers';
import { SponsoredPropertiesProvider } from '@shared-ui/retail-lodging-sponsored-properties-carousel';
import { RecommendationModuleProvider } from '@shared-ui/retail-recommendation-module';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { componentLibrary } from 'components/infosite/template-components';
export const TemplateRenderer = inject('pageLayout')(observer(({
  pageLayout
}) => {
  const getTemplate = view => {
    var _a, _b;
    return React.createElement(ExperienceTemplateRenderer, {
      templateComponents: (_b = (_a = pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.getPDPTemplate(`${pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.templateName}${view}`)) === null || _a === void 0 ? void 0 : _a.components) !== null && _b !== void 0 ? _b : []
    });
  };
  return React.createElement(ExperienceTemplateRendererContextProvider, {
    library: componentLibrary
  }, React.createElement(PropertyOffersContextProvider, null, React.createElement(SponsoredPropertiesProvider, null, React.createElement(RecommendationModuleProvider, null, React.createElement(CarouselProvider, null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, getTemplate('_SMALL')), React.createElement(ViewLarge, null, getTemplate('_LARGE'))))))));
}));