import { isVrbo } from 'src/common/stores/context-store-utils';
export class SearchValidation {
  constructor(formatText, context) {
    this._formatText = formatText;
    this._context = context;
    this.adults = this.adults.bind(this);
    this.children = this.children.bind(this);
    this.destination = this.destination.bind(this);
    this.endDate = this.endDate.bind(this);
    this.formatText = this.formatText.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.startDate = this.startDate.bind(this);
  }
  adults({
    adults
  }) {
    if (!adults || parseInt(adults, 10) <= 0) {
      return;
    }
  }
  children({
    children
  }) {
    if (!children) {
      return;
    }
    const {
      formatText
    } = this;
    const childAges = children.split(',');
    const message = childAges.length === 1 ? formatText('hotels.search.validation.missingChildAge.text') : formatText('hotels.search.validation.missingChildrenAges.text');
    let error = false;
    childAges.forEach(child => {
      if (!child.match(/\d{1,2}_\d{1,2}/)) {
        // i.e. roomNumber_childAge, e.g. 1_4
        error = true;
      }
    });
    return error ? message : undefined;
  }
  destination({
    destination
  }) {
    if (!destination) {
      return this.formatText('hotels.search.validation.invalidDestination.text');
    }
  }
  endDate({
    startDate,
    endDate
  }) {
    const {
      isValidDate,
      formatText
    } = this;
    const start = startDate ? new Date(startDate) : undefined;
    const end = endDate ? new Date(endDate) : undefined;
    if (!isValidDate(end)) {
      return formatText('hotels.search.validation.invalidToDate.text');
    }
    if (start && end && isValidDate(start)) {
      const maxEndDate = isVrbo(this._context) ? null : new Date(start.getFullYear(), start.getMonth(), start.getDate() + 28);
      if (end < start) {
        return formatText('hotels.search.validation.invalidDateRange.text');
      }
      if (maxEndDate) {
        maxEndDate.setHours(0, 0, 0, 0);
      }
      end.setHours(0, 0, 0, 0);
      if (maxEndDate !== null && end > maxEndDate) {
        return formatText('hotels.search.validation.invalidDateDateRangeLength.text');
      }
    }
  }
  formatText(key) {
    return this._formatText(key);
  }
  isValidDate(date) {
    return date && date.toString() !== 'Invalid Date';
  }
  startDate({
    startDate
  }) {
    const {
      isValidDate,
      formatText
    } = this;
    const start = (startDate === null || startDate === void 0 ? void 0 : startDate.length) ? new Date(startDate) : undefined;
    if (!isValidDate(start)) {
      return formatText('hotels.search.validation.invalidFromDate.text');
    }
  }
}