import * as React from 'react';
import { stringify } from 'query-string';
import { useLocalization } from '@shared-ui/localization-context';
import { FormActionProvider } from '@shared-ui/lodging-form';
import { ChildModel, RoomModel } from 'stores/models';
export function buildSubmitData(inputs, action, overrides) {
  const formData = {};
  for (const [key, value] of inputs.entries()) {
    formData[key] = value;
  }
  if (overrides) {
    formData.startDate = overrides.startDate;
    formData.endDate = overrides.endDate;
  }
  const submitUrl = `${action}?${stringify(formData)}`;
  return {
    formData,
    submitUrl
  };
}
export const ActionProvider = ({
  formAction,
  onChange,
  onSubmit,
  onError,
  searchCriteria,
  children
}) => {
  const {
    formatDate
  } = useLocalization();
  const newCriteria = searchCriteria.clone();
  const dispatch = React.useMemo(() => action => {
    var _a, _b, _c, _d;
    switch (action.type) {
      case 'change':
        if (!onChange) {
          return;
        }
        if (action.field === 'dates') {
          const dateFormatter = dateInput => formatDate(new Date(dateInput.year, dateInput.month - 1, dateInput.day), {
            raw: 'yyyy-MM-dd'
          });
          const dates = action.value;
          newCriteria.startDate = ((_a = dates.dates) === null || _a === void 0 ? void 0 : _a.checkInDate) ? dateFormatter((_b = dates.dates) === null || _b === void 0 ? void 0 : _b.checkInDate) : null;
          newCriteria.endDate = ((_c = dates.dates) === null || _c === void 0 ? void 0 : _c.checkOutDate) ? dateFormatter((_d = dates.dates) === null || _d === void 0 ? void 0 : _d.checkOutDate) : null;
        }
        if (action.field === 'travelers') {
          const {
            rooms
          } = action.value;
          const roomInputArray = Array.isArray(rooms) ? rooms : [rooms];
          newCriteria.rooms = roomInputArray.map(room => {
            var _a;
            return new RoomModel({
              adults: room.adults,
              children: (_a = room === null || room === void 0 ? void 0 : room.children) === null || _a === void 0 ? void 0 : _a.map(child => new ChildModel({
                age: child.age
              }))
            });
          });
        }
        onChange(newCriteria, action.field);
        break;
      case 'error':
        if (onError && typeof onError === 'function') {
          onError();
        }
        break;
      case 'submit':
        action.event.preventDefault();
        if (!onSubmit) {
          return;
        }
        onSubmit(buildSubmitData(action.data, formAction));
        break;
      default:
        break;
    }
  }, [formatDate, onChange, newCriteria, onError, onSubmit, formAction]);
  return React.createElement(FormActionProvider, {
    value: dispatch
  }, children);
};
// Currently supports only travelers field
export const ActionProviderDatedMobile = ({
  onChange,
  children
}) => {
  const dispatch = React.useMemo(() => action => {
    if (!(action.type === 'change' && action.field === 'travelers')) {
      return;
    }
    const {
      rooms
    } = action.value;
    const roomInputArray = Array.isArray(rooms) ? rooms : [rooms];
    const adultTravelers = roomInputArray.map(room => room.adults).filter(adult => adult > 0).join(',');
    const childTravelers = roomInputArray.map((room, index) => {
      var _a;
      return (_a = room.children) === null || _a === void 0 ? void 0 : _a.map(child => `${index + 1}_${child.age}`).join(',');
    }).filter(child => child && child !== '').join(',');
    onChange && onChange({
      adults: adultTravelers,
      children: childTravelers
    });
  }, [onChange]);
  return React.createElement(FormActionProvider, {
    value: dispatch
  }, children);
};