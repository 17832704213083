import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core/cards';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { ViewRow } from '@egds/react-search-experience';
import { DeviceType } from 'src/common/__generated__/api';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { RefTarget } from '@shared-ui/ref-context';
import { ProductGalleryContext } from '@shared-ui/retail-product-gallery';
import { useSignalSubscriber } from '@shared-ui/signal-events-context';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { SharedUiPropertySearchLinkWrapper } from 'components/infosite/shared-ui/offers/property-search-link-wrapper';
import { PropertySummaryHeader } from './property-summary-header';
import { SharedUiSaveTripItemWrapper } from './shared-ui/details/save-trip-item-wrapper';
import { SharedUiShareActionsWrapper } from './shared-ui/details/share-actions-wrapper';
import { SharedUiShortlistWrapper } from './shared-ui/details/shortlist-wrapper';
import { SharedUiSocialShareButtonWrapper } from './shared-ui/details/social-share-button-wrapper';
import { SharedUiStickyPriceHeaderWrapper } from './shared-ui/offers/shared-ui-property-sticky-price-header-wrapper';
export const PropertySummaryBackLinkWrapper = inject('currentHotel', 'multiItem', 'context')(observer(({
  currentHotel,
  multiItem,
  context
}) => {
  var _a, _b;
  const searchCriteria = currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria;
  const regionId = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.regionId;
  const startDate = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.startDate;
  const endDate = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.endDate;
  const isDateLess = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.isDateless;
  const isMultiItem = (_b = (_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.cartInfo) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  const domain = context === null || context === void 0 ? void 0 : context.site.domain;
  const [isLuxury, setLuxury] = React.useState(false);
  const {
    deviceInformation
  } = context || {};
  const {
    useControlGalleryAdaptexCampaign
  } = React.useContext(ProductGalleryContext);
  useSignalSubscriber({
    query: {
      type: 'LUXURY_PROPERTY'
    },
    onSignal: ({
      payload
    }) => setLuxury(() => payload.message)
  });
  const cardContent = (viewLarge = false) => React.createElement("div", null, React.createElement(EGDSLayoutFlex, {
    alignItems: "center",
    style: {
      minHeight: '3rem'
    },
    space: viewLarge ? 'one' : undefined
  }, React.createElement(EGDSLayoutFlexItem, {
    grow: 1
  }, React.createElement("div", null, React.createElement(SharedUiPropertySearchLinkWrapper, null))), !isDateLess && viewLarge && !isMultiItem && React.createElement(Experiment, {
    name: "Price_Summary_above_the_scroll_line_on_PDP"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement(SharedUiStickyPriceHeaderWrapper, {
    hideCtaButton: true,
    wrapPriceSummary: true
  }))), React.createElement(ExperimentVariant, {
    bucket: 2
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement(SharedUiStickyPriceHeaderWrapper, {
    wrapPriceSummary: true
  })))), (deviceInformation === null || deviceInformation === void 0 ? void 0 : deviceInformation.type) === DeviceType.DESKTOP ? React.createElement(Experiment, {
    name: "V2_VRBO_Desktop_Branch_Sharing"
  }, React.createElement(ExperimentControl, null, domain && React.createElement(SharedUiShareActionsWrapper, {
    domain: domain
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SharedUiSocialShareButtonWrapper, null))) : React.createElement(Experiment, {
    name: "MWEB_BEX_HCOM_Branch_Sharing"
  }, React.createElement(ExperimentControl, null, domain && React.createElement(SharedUiShareActionsWrapper, {
    domain: domain
  })), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(SharedUiSocialShareButtonWrapper, null))), React.createElement("div", {
    "data-stid": "favorite-save-button"
  }, React.createElement(SharedUiShortlistWrapper, null), React.createElement(SharedUiSaveTripItemWrapper, null))));
  const controlHeader = React.createElement(React.Fragment, null, React.createElement(PropertySummaryHeader, {
    multiItem: multiItem,
    regionId: regionId,
    startDate: startDate,
    endDate: endDate
  }), React.createElement(EGDSCard, {
    className: "custom-css-flat-border-top custom-css-flat-border-bottom",
    overflow: true
  }, React.createElement(EGDSCardContentSection, {
    padded: false
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'one',
      inline: 'two'
    }
  }, cardContent(false)))));
  const variantHeader = (multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) && React.createElement(ViewRow, {
    layout: "fullwidth",
    paddedViewRow: false,
    canvasTheme: "primary"
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'three'
    }
  }, React.createElement("div", {
    "data-stid": "property-summary-header"
  }, React.createElement(PropertySummaryHeader, {
    multiItem: multiItem,
    regionId: regionId,
    startDate: startDate,
    endDate: endDate
  }))));
  const {
    exposure: adaptexCampaignMosaicLargeScreensExposure
  } = useExperiment('Adaptex_campaign_PDP_mosaic_for_large_screens');
  const renderAdaptexCampaign = () => {
    if (adaptexCampaignMosaicLargeScreensExposure.bucket === 0 || adaptexCampaignMosaicLargeScreensExposure.bucket === 1 && useControlGalleryAdaptexCampaign) {
      return React.createElement(EGDSCard, {
        className: "custom-css-flat-border-top custom-css-flat-border-bottom",
        overflow: true
      }, React.createElement(EGDSCardContentSection, {
        padded: false
      }, React.createElement(PropertySummaryHeader, {
        multiItem: multiItem,
        regionId: regionId,
        startDate: startDate,
        endDate: endDate
      }), React.createElement(EGDSSpacing, {
        padding: {
          block: 'two',
          inline: 'two'
        }
      }, cardContent(true))));
    }
    return variantHeader;
  };
  return React.createElement(EGDSLayoutFlexItem, null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(Experiment, {
    name: "mWeb_larger_image_for_top_of_PDP_gallery"
  }, React.createElement(ExperimentControl, null, React.createElement(Experiment, {
    name: "Adaptex_campaign_PDP_mosaic_for_small_screens"
  }, React.createElement(ExperimentControl, null, controlHeader), React.createElement(ExperimentVariant, {
    bucket: 1
  }, variantHeader))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, variantHeader), React.createElement(ExperimentVariant, {
    bucket: 2
  }, variantHeader))), React.createElement(ViewMedium, null, React.createElement(RefTarget, {
    name: "SummaryBackLink"
  }, isLuxury ? variantHeader : renderAdaptexCampaign()))));
}));