import * as React from 'react';
import { ReferrerContext } from '@shared-ui/lodging-form';
export const ReferrerContextProvider = ({
  children
}) => {
  const referrerContext = React.useMemo(() => ({
    referrer: {
      pagePrefix: 'HOT.HIS'
    }
  }), []);
  return React.createElement(ReferrerContext.Provider, {
    value: referrerContext
  }, children);
};
ReferrerContextProvider.displayName = 'ReferrerContextProvider';