import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { getShoppingContextInput } from 'src/common/stores/adapters';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import CarouselContext from 'src/common/stores/contexts/carousel-context-provider';
import { usePropertyCarouselContexts } from 'src/common/utils/carousel-utils';
import { useExperiment } from '@shared-ui/experiment-context';
import { SponsoredPropertiesCarouselWrapper } from '../../meso/sponsored-properties-carousel-wrapper';
import { RecommendationsModuleWrapper } from '../similar-hotels/recommendations-module-wrapper';
export const PropertySecondaryCarousel = inject('currentHotel', 'multiItem')(observer(props => {
  var _a, _b, _c;
  const {
    context,
    searchCriteria,
    availableRooms,
    isLoading
  } = usePropertyCarouselContexts();
  const multiItemContext = (_a = props.multiItem) === null || _a === void 0 ? void 0 : _a.multiItemContext;
  const shoppingContext = getShoppingContextInput(multiItemContext);
  const propertySearchCriteria = adaptSearchCriteria(context, searchCriteria);
  const {
    sponsoredCarouselRendered,
    recommendationsCarouselRendered
  } = React.useContext(CarouselContext);
  const isSmaShPropertyRecsNewCarouselVariant = useExperiment('SmaSh_Property_Recs_New_Carousel').exposure.bucket === 1;
  propertySearchCriteria.primary.destination.excludedPropertyIds = [`${(_c = (_b = props.currentHotel) === null || _b === void 0 ? void 0 : _b.hotelId) !== null && _c !== void 0 ? _c : null}`];
  if (!searchCriteria.startDate && !searchCriteria.endDate) {
    return null;
  }
  if (!availableRooms && recommendationsCarouselRendered && !isLoading) {
    return React.createElement(SponsoredPropertiesCarouselWrapper, null);
  }
  if (!shoppingContext.multiItem && sponsoredCarouselRendered && isSmaShPropertyRecsNewCarouselVariant) {
    return React.createElement("div", {
      "data-stid": "property-recs"
    }, React.createElement(EGDSSpacing, {
      border: ['blockstart', 'blockend'],
      margin: {
        small: {
          inline: 'three'
        },
        medium: {
          inline: 'unset'
        }
      },
      padding: {
        block: 'six',
        small: {
          block: 'three'
        }
      }
    }, React.createElement("div", null, React.createElement(RecommendationsModuleWrapper, null))));
  }
  return null;
}));