import * as React from 'react';
import { PropertyBookByPhone } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const BookByPhoneWrapper = ({
  inputs,
  className
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(PropertyBookByPhone, {
    inputs: inputs,
    className: className,
    skipSsr: cSRPropertyDetailsQuery
  });
};
export const SharedUiBookByPhoneWrapper = withSharedUiPropertyDetails(BookByPhoneWrapper);
SharedUiBookByPhoneWrapper.displayName = 'SharedUiBookByPhoneWrapper';