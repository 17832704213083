import * as React from 'react';
import { PropertySpaces } from '@shared-ui/retail-lodging-property-spaces';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
const PropertySpacesWrapper = ({
  inputs
}) => React.createElement(PropertySpaces, {
  inputs: inputs
});
PropertySpacesWrapper.displayName = 'SpaceDetailsWrapper';
export const SharedUiPropertySpacesWrapper = withSharedUiPropertyDetails(PropertySpacesWrapper);
SharedUiPropertySpacesWrapper.displayName = 'SharedUiPropertySpacesWrapper';