var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
let FocusTarget = class FocusTarget extends React.Component {
  constructor(props) {
    super(props);
    this.focusRef = null;
    this.focusRef = React.createRef();
    this.checkFocusTarget = this.checkFocusTarget.bind(this);
  }
  checkFocusTarget({
    targetKey,
    executor
  }) {
    const props = this.props;
    if (!targetKey || !executor) {
      return;
    }
    if (typeof props.targetKey === 'string' && props.targetKey.length > 0 && props.targetKey === targetKey) {
      if (typeof executor === 'function') {
        const focusRef = this.focusRef && this.focusRef.current;
        executor(this.props.targetKey, () => {
          if (focusRef && typeof focusRef.scrollIntoView === 'function') {
            focusRef.scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            });
          }
        });
      }
    }
  }
  componentDidMount() {
    this.disposer = reaction(() => {
      const uiPageStore = this.props.uiPage;
      const focusItem = uiPageStore && uiPageStore.focusItem;
      const targetKey = focusItem && focusItem.targetKey;
      const executor = focusItem && focusItem.executor;
      return {
        targetKey,
        executor
      };
    }, this.checkFocusTarget);
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  render() {
    return React.createElement(React.Fragment, null, React.createElement("span", {
      ref: this.focusRef
    }), this.props.children);
  }
};
FocusTarget = __decorate([inject('uiPage'), observer], FocusTarget);
export { FocusTarget };