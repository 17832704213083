import * as React from 'react';
import { EGDSLayoutGrid } from '@egds/react-core/layout-grid';
import { ExperienceTemplateRenderer } from 'experience-template-renderer-react';
import { number, object, optional, safeParse } from 'valibot';
export const LayoutGrid = ({
  templateComponent
}) => {
  var _a;
  const {
    children,
    config
  } = templateComponent;
  const configSchema = optional(object({
    columns: number()
  }));
  const result = safeParse(configSchema, config);
  if (!result.success || !children || children.length === 0) {
    return null;
  }
  const {
    columns
  } = (_a = result.output) !== null && _a !== void 0 ? _a : {};
  return React.createElement(EGDSLayoutGrid, {
    columns: columns
  }, children && React.createElement(ExperienceTemplateRenderer, {
    templateComponents: children
  }));
};