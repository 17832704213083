import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { CancellationPolicyInfo } from '@shared-ui/lodging-property-offers';
import { RefTarget } from '@shared-ui/ref-context';
import { SimpleLazyLoadWrapper } from '../../../../components/common/lazy/simple-lazy-load-wrapper';
import { withSharedUiPropertyOffers } from '../offers/shared-ui-property-offers';
const CancellationPolicyInfoWrapper = ({
  inputs
}) => React.createElement(EGDSSpacing, {
  margin: {
    block: 'six'
  }
}, React.createElement(RefTarget, {
  name: "CancellationPolicyInfo"
}, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(CancellationPolicyInfo, {
  inputs: inputs,
  skipSsr: true
}))));
export const SharedUiCancellationPolicyInfoWrapper = withSharedUiPropertyOffers(CancellationPolicyInfoWrapper);
SharedUiCancellationPolicyInfoWrapper.displayName = 'SharedUiCancellationPolicyInfoWrapper';