import * as React from 'react';
import { HighlightedBenefits } from '@shared-ui/lodging-property-offers';
import { withSharedUiPropertyOffers } from './shared-ui-property-offers';
const HighlightedBenefitsWrapper = ({
  inputs
}) => React.createElement(HighlightedBenefits, {
  inputs: inputs,
  skipSsr: true
});
HighlightedBenefitsWrapper.displayName = 'SharedUiHighlightedBenefitsWrapper';
export const SharedUiHighlightedBenefitsWrapper = withSharedUiPropertyOffers(HighlightedBenefitsWrapper);