/* eslint-disable camelcase */
import * as React from 'react';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
export const EventWrapper = ({
  children
}) => {
  const track = useEgClickstream();
  React.useEffect(() => {
    track({
      event: {
        event_type: 'Interaction',
        event_name: 'clickstream_sdk.presented',
        event_version: '1.0.0'
      }
    });
  }, []);
  return React.createElement(React.Fragment, null, children);
};
export default EventWrapper;