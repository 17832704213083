import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { SimpleLazyLoadWrapper } from 'src/common/components/common/lazy/simple-lazy-load-wrapper';
import { PropertyAboutThisProperty } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const AboutThisPropertyWrapper = ({
  inputs,
  columns
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      block: 'six'
    }
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(PropertyAboutThisProperty, {
    inputs: inputs,
    columns: columns,
    skipSsr: cSRPropertyDetailsQuery
  })));
};
export const SharedUiAboutThisPropertyWrapper = withSharedUiPropertyDetails(AboutThisPropertyWrapper);
SharedUiAboutThisPropertyWrapper.displayName = 'SharedUiAboutThisPropertyWrapper';