import * as React from 'react';
import { Link } from 'react-router-dom';
import { LocalizedText } from '@shared-ui/localization-context';
export const ErrorLink = () => React.createElement("p", {
  className: "error",
  "data-stid": "infosite-fetch-error"
}, React.createElement(LocalizedText, {
  message: "hotels.infosite.errorMessage.text"
}), React.createElement(Link, {
  to: '/Hotels'
}, React.createElement(LocalizedText, {
  message: "hotels.infosite.tryAgain.text"
})));