import { getShoppingContextInput } from 'stores/adapters';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
import { InfositeQueryAdapter } from 'stores/adapters/infosite-query-adapter';
export const getPropertyOffersInput = (context, currentHotel, urlParameters, multiItemContext, analyticsStore) => {
  const {
    searchCriteria,
    hotelId,
    l10n
  } = currentHotel;
  const searchCriteriaJson = (searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.toJSON()) || {};
  const criteria = Object.assign(Object.assign({}, InfositeQueryAdapter.parseOut(searchCriteriaJson, l10n)), searchCriteriaJson);
  const {
    selectedRatePlan,
    selectedRoomType,
    badgeType,
    top_dp: srpDisplayPrice,
    top_cur: srpCurrency,
    mipt: multiItemPriceToken,
    pwa_ts: offerTimestamp,
    rfrr: referrer,
    srpStartDate: originalStartDate,
    srpEndDate: originalEndDate
  } = urlParameters;
  return {
    propertyId: String(hotelId),
    searchCriteria: adaptSearchCriteria(context, criteria, undefined, [], analyticsStore),
    shoppingContext: getShoppingContextInput(multiItemContext),
    travelAdTrackingInfo: getTravelAdTrackingInfo(urlParameters),
    searchOffer: getSearchOffer(selectedRoomType, selectedRatePlan, badgeType, multiItemPriceToken, srpDisplayPrice, srpCurrency, offerTimestamp, originalStartDate, originalEndDate),
    referrer: referrer || null
  };
};
export const getTravelAdTrackingInfo = urlParameters => {
  if (urlParameters.trackingData) {
    return {
      beaconIssued: urlParameters.beaconIssued,
      candidateHmGuid: urlParameters.candidateHmGuid,
      position: urlParameters.position,
      rank: urlParameters.rank,
      slots: urlParameters.slots,
      testVersionOverride: urlParameters.testVersionOverride,
      trackingData: urlParameters.trackingData
    };
  }
  return null;
};
export const getSearchOffer = (selectedRoomType, selectedRatePlan, badgeType, multiItemPriceToken, srpDisplayPrice, srpCurrency, offerTimestamp, originalStartDate, originalEndDate) => {
  if (selectedRoomType && selectedRatePlan) {
    return {
      offerPrice: getOfferPrice(multiItemPriceToken, srpDisplayPrice, srpCurrency, offerTimestamp),
      roomTypeId: selectedRoomType,
      ratePlanId: selectedRatePlan,
      offerDetails: getOfferDetails(badgeType, originalStartDate, originalEndDate)
    };
  }
  return null;
};
export const getOfferDetails = (badgeType, originalStartDate, originalEndDate) => {
  const originalDateRange = originalStartDate && originalEndDate ? [{
    id: 'srpStartDate',
    value: originalStartDate
  }, {
    id: 'srpEndDate',
    value: originalEndDate
  }] : [];
  const badge = badgeType ? [{
    id: 'selectedRoomBadgeType',
    value: 'brandedDeal'
  }] : [];
  return [...originalDateRange, ...badge];
};
export const getOfferPrice = (priceToken, srpDisplayPrice, srpCurrency, offerTimestamp) => {
  if (priceToken || srpDisplayPrice && srpCurrency) {
    return {
      multiItemPriceToken: priceToken,
      offerTimestamp,
      price: srpDisplayPrice && {
        amount: Number(srpDisplayPrice),
        currency: srpCurrency
      } || null
    };
  }
  return null;
};