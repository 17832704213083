import * as React from 'react';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { useSearchCriteriaContext } from 'src/common/stores/contexts';
import { usePlatformContext } from 'src/common/stores/contexts/platformContext';
import { useRefProvider } from 'src/common/stores/contexts/ref-provider';
import { PropertySaveTripItem } from '@shared-ui/lodging-property-details';
import { buildStateProvider } from '@shared-ui/provided-state-context';
import { ValidatedInputsState } from '@shared-ui/trips-save-item';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const ValidatedInputsStateProvider = buildStateProvider(new ValidatedInputsState());
/**
 * This component should be used in other wrapper components that need to use the PropertySaveTripItem component.
 * This component should not be used directly in template components. Use SharedUiSaveTripItemWrapper instead.
 */
export const SharedUiSaveTripItem = ({
  inputs
}) => {
  const context = usePlatformContext();
  const searchCriteria = useSearchCriteriaContext();
  const propertySearchCriteria = adaptSearchCriteria(context, searchCriteria);
  const searchCriteriaPrimary = propertySearchCriteria.primary;
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  const [saveButtonRef] = useRefProvider('tripSaveItem');
  /**
   * Save Trip Item needs to have the latest search criteria so it can correctly save items
   * The searchCriteria in the inputs is cached on pageload and not updated when a user changes dates or rooms
   */
  return React.createElement(ValidatedInputsStateProvider, null, React.createElement("div", {
    ref: saveButtonRef
  }, React.createElement(PropertySaveTripItem, {
    inputs: inputs,
    searchCriteria: searchCriteriaPrimary,
    skipSsr: cSRPropertyDetailsQuery
  })));
};
SharedUiSaveTripItem.displayName = 'SharedUiSaveTripItem';