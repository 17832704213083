import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PropertyNumberMessage } from '@shared-ui/lodging-property-details';
import { withSharedUiPropertyDetails } from './shared-ui-property-details';
import { usePropertyDetailsQueryCSRExperiment } from './use-property-details-query';
const PropertyNumberWrapper = ({
  inputs
}) => {
  const cSRPropertyDetailsQuery = usePropertyDetailsQueryCSRExperiment();
  return React.createElement(EGDSSpacing, {
    margin: {
      blockend: 'four',
      blockstart: 'two'
    }
  }, React.createElement("div", null, React.createElement(PropertyNumberMessage, {
    inputs: inputs,
    skipSsr: cSRPropertyDetailsQuery
  })));
};
export const SharedUiPropertyNumberWrapper = withSharedUiPropertyDetails(PropertyNumberWrapper);
SharedUiPropertyNumberWrapper.displayName = 'SharedUiPropertyNumberWrapper';