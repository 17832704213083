import * as React from 'react';
export const NavigationRegion = ({
  className,
  children
}) => {
  const classes = ['navigation-region', 'uitk-layout-position-relative', className].filter(val => val).join(' ');
  return React.createElement("section", {
    className: classes,
    "data-stid": "navigation-region"
  }, children);
};